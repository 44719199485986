import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SummerCamp = () => {
    const [open, setOpen] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false); // State for download dialog
    const [formData, setFormData] = useState({
        name: "",
        number: "",
        discount: "",
    });

    const [filters, setFilters] = useState({
        name: "",
        discount: "",
    });

    const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
    const isAuthorized = userTabIds.includes(15);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownloadOpen = () => {
        setDownloadOpen(true);
    };

    const handleDownloadClose = () => {
        setDownloadOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/summerCamp/addRefer`, formData);
            toast.success(`Added Successfully`);
            handleClose();
        } catch (error) {
            console.error("Error adding referrals: ", error);
            toast.error(`Error in adding`);
        }
    };

    const handleDownloadSubmit = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/summerCamp/export`, filters, {
                responseType: "blob",
            });
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${filters?.name || filters.discount}referrals.xlsx`);
            document.body.appendChild(link);
            link.click();
            toast.success(`Downloaded Successfully`);
            handleDownloadClose();
        } catch (error) {
            console.error("Error downloading referrals: ", error);
            toast.error("Error in downloading");
        }
    };

    if (!isAuthorized) {
        return (
            <div className="container-fluid mt-5">
                <h2>You are unauthorized to access this page</h2>
            </div>
        )
    }

    return (
        <div className="container-fluid mt-5">
            <Card>
                <CardContent>
                    <ToastContainer />
                    <Typography variant="h5" gutterBottom>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            Summer Camp Referrals
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickOpen}
                                    style={{ marginRight: "8px" }}
                                >
                                    Add New Referrals
                                </Button>
                                <Button variant="contained" color="secondary" onClick={handleDownloadOpen}>
                                    Download Referrals
                                </Button>
                            </div>
                        </div>
                    </Typography>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Referrals</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Number"
                        type="number"
                        fullWidth
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Discount"
                        type="number"
                        fullWidth
                        name="discount"
                        value={formData.discount}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Download Dialog */}
            <Dialog open={downloadOpen} onClose={handleDownloadClose}>
                <DialogTitle>Download Referrals</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                    />
                    <TextField
                        margin="dense"
                        label="Discount"
                        type="number"
                        fullWidth
                        name="discount"
                        value={filters.discount}
                        onChange={handleFilterChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownloadClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDownloadSubmit} color="primary">
                        Download
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default SummerCamp;