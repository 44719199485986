import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import service from "../stage/stageService";
import {Chip,Box} from  '@mui/material'
import { ToastContainer,toast } from "react-toastify";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import  isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import  isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { useLocalStorage } from "@uidotdev/usehooks";

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)




const SkillReport = () => {
  const [missions, setMissions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [token,setToken] = useLocalStorage('token',null);
  const [fromDate, setFromDate] = useState(dayjs("01/01/1995"))
  const [selectedSkillName,setSelectedSkillName]=useState([])
  const [toDate, setToDate] = useState(dayjs());
  const [filter, setFilter] = useState("");
  const [SkillStagePair, setSkillStagePair] = useState([]);
  const [selectedSkillObj,setSelectedSkillObj]=useState([])
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedMission, setSelectedMission] = useState([]);
  const [reports, setReports] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(12);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };
  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/mission-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };
  const handleExportClick = async () => {
    if (selectedSkill.length===0) {
      toast.error("No skill selected");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-missions`,
        {
          name: filter,
          mission_id: selectedMission,
          skill_id: selectedSkill,
          stage_id: selectedStage,
          fromDate:fromDate,
          toDate:toDate
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MissionAttempts.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };
  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };
  const handleSkillChange = (event) => {
    const selectedSkillId = event.target.value;
    // console.log(selectedSkillId);
    let updatedSelectedSkills = [...selectedSkillObj]; 
  const skillIndex = updatedSelectedSkills.findIndex(
    (obj) => obj.id === selectedSkillId[selectedSkillId.length-1].id
  );

  if (skillIndex !== -1) {
    updatedSelectedSkills.splice(skillIndex, 1); // Remove the skill
  } else {
    updatedSelectedSkills.push(selectedSkillId[selectedSkillId.length-1]); // Add the skill
  }
  let updatedSelectedSkillIds = updatedSelectedSkills.map((skill) => skill.id);
  let updatedSelectedSkillNames = updatedSelectedSkills.map((skill) => skill.name);

  setSelectedSkillObj(updatedSelectedSkills);
  setSelectedSkill(updatedSelectedSkillIds);
  setSelectedSkillName(updatedSelectedSkillNames);
  setSelectedStage([]);
  fetchStages(updatedSelectedSkillIds);
  };

  const handleStageChange = (event) => {
    const selectedStageId = event.target.value;
    // console.log(event.target.value);
    setSelectedStage(selectedStageId);
    // console.log(stages);
    fetchMissions(selectedStageId);
    setSelectedMission([])
  };

  const fetchMissions = async (stageId) => {
    console.log(stageId);
    if (stageId.length===0) {
      setSkillStagePair([]);
      setMissions([]);
      return;
    }
    const encoded = encodeURIComponent(JSON.stringify(stageId));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            value: encoded,
          },
        }
      );
      if (response.status === 200) {
        let SSPair = [];
        stages.skills.map((skill) => {
          stages.stages.map((stage) => {
            // console.log(stage);
            if (stage.skill_id === skill.skill_id) {
              SSPair.push({
                name: `${skill.skill_name}/${stage.stage_name}`,
                stage_id: stage.stage_id,
              });
            }
          });
        });
        // console.log(SSPair);
        setSkillStagePair(SSPair);
        setMissions(response.data.data);
      } else {
        console.error("Error fetching missions:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching missions:", error);
    }
  };
  const fetchStages = async (selectedSkill) => {
    if (selectedSkill.length===0) {
      setStages([])
      return
    }
    try {

      const fetchedStages = await service.getSpecificStages(
        token,
        selectedSkill
      );
      setStages(fetchedStages);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };

  // useEffect(()=>{
  //   console.log(skills);
  //   console.log(stages);
  //   console.log(missions);
  // },[skills,stages,missions])

  const handleReset = () => {
    setFilter("");
    setSelectedSkill([]);
    setSelectedMission([]);
    setSelectedStage([]);
  };
  useEffect(() => {
    fetchSkills();
    // fetchReports();
  }, [selectedSkill, filter]);

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    );
  }

  // console.log(stages);

  return (
    <div className="container-fluid mt-3 p-0">
      <div className="align-center-all full-height" style={{padding: 0}}>
          <CardContent style={{padding: 0}}>
            <div className="sub-title">Skills Report</div>
          <div
            style=
            {{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div className="fieldContainer">
              <div className="leftFields">
                <FormControl
                  fullWidth
                  className="mb-3 customTextfield"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    width: '32.051vw'
                  }}
                >
                  <InputLabel id="skill-label">Skill</InputLabel>
                  <Select
                    labelId="skill-label"
                    id="skill-select"
                    label="Skill"
                    value={selectedSkillObj}
                    multiple
                    onChange={handleSkillChange}
                    renderValue={() => "Skills"}
                  >
                    {/* <MenuItem value="">None</MenuItem> */}
                    {skills.map((skill) => {
                      const temp = { name: skill.skill_name, id: skill.skill_id };
                      return <MenuItem key={skill.skill_id} value={temp}>
                        <Checkbox
                          // checked={selectedSkill.indexOf(skill.skill_id) > -1}
                          checked={selectedSkillObj.some(obj => obj.id === skill.skill_id)}
                        />
                        <ListItemText primary={skill.skill_name} />
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  className="mb-3 customTextfield"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                  }}
                >
                  <InputLabel id="stages-label">Stages</InputLabel>
                  <Select
                    labelId="stages-label"
                    id="stages-select"
                    label="Stage"
                    value={selectedStage}
                    multiple
                    onChange={handleStageChange}
                    // onClick={handleStageChange}
                    renderValue={() => "Stages"}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {Array.isArray(stages.skills) &&
                      stages.skills.map((skill) => {
                        const elements = [];

                        // Add ListSubheader for the current skill
                        elements.push(
                          <ListSubheader key={`Skill${skill.skill_id}`}>
                            {skill.skill_name}
                          </ListSubheader>
                        );

                        // Add MenuItems for stages associated with the current skill
                        if (Array.isArray(stages.stages)) {
                          stages.stages.forEach((stage) => {
                            if (stage.skill_id === skill.skill_id) {
                              elements.push(
                                <MenuItem
                                  key={stage.stage_id}
                                  value={stage.stage_id}
                                >
                                  <Checkbox
                                    checked={
                                      selectedStage.indexOf(stage.stage_id) >
                                      -1
                                    }
                                  />
                                  <ListItemText primary={stage.stage_name} />
                                </MenuItem>
                              );
                            }
                          });
                        }

                        return elements;
                      })}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  className="mb-3 customTextfield"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                  }}
                >
                  <InputLabel id="mission-label">Mission</InputLabel>
                  <Select
                    labelId="mission-label"
                    id="mission-select"
                    label="Mission"
                    multiple
                    onChange={(e) => {
                      const selectedMissionId = e.target.value;
                      // console.log(selectedMissionId);
                      setSelectedMission(selectedMissionId);
                    }}
                    value={selectedMission}
                    renderValue={() => "Missions"}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {SkillStagePair.map((SSPair) => {
                      if (selectedStage.includes(SSPair.stage_id)) {
                        let element = [];
                        element.push(
                          <ListSubheader key={SSPair.name}>
                            {SSPair.name}
                          </ListSubheader>
                        );
                        // Iterate over missions array and generate MenuItems for missions associated with the current stage
                        missions.forEach((mission) => {
                          // console.log(mission.stage_id, SSPair.stage_id);
                          if (SSPair.stage_id === mission.stage_id) {
                            element.push(
                              <MenuItem
                                key={mission.mission_id}
                                value={mission.mission_id}
                              >
                                <Checkbox
                                  checked={
                                    selectedMission.indexOf(
                                      mission.mission_id
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={mission.mission_name}
                                />
                              </MenuItem>
                            );
                          }
                        });
                        return element;
                      }
                      return null; // If stage_id is not included in selectedStage, return null or an empty array
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="rightFields">
                <div style={{display: 'flex', flexDirection: 'row', gap:'0.5vw'}}>
                <FormControl
                  className="mb-3 dateField"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    width: '16vw'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="From" value={fromDate} onChange={(value) => { setFromDate(dayjs(value).startOf('day')) }} />
                  </LocalizationProvider>
                </FormControl>
                <FormControl
                  fullWidth
                  className="mb-3 dateField"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    width: '16vw'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="To" value={toDate} onChange={(value) => { setToDate(dayjs(value).endOf('day')) }} />
                  </LocalizationProvider>
                </FormControl>
                </div>
                <TextField
                  label="Name"
                  variant="outlined"
                  value={filter}
                  fullWidth
                  onChange={handleInputChange}
                  placeholder="Enter Name"
                  className="customTextfield mb-3"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
                <FormControl
                  className="mb-3 customTextfield"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    width: '32.051vw'
                  }}
                >
                <InputLabel id="skill-label">Skill</InputLabel>
                <Select
                  labelId="skill-label"
                  id="skill-select"
                  label="SkillFilter"
                  multiple
                  value={selectedSkill}
                  // onChange={handleSkillChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedSkillName.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  disabled
                  fullWidth
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  {skills.map((skill) => (
                    <MenuItem key={skill.skill_id} value={skill.skill_id}>
                      <Checkbox
                        checked={selectedSkill.indexOf(skill.skill_id) > -1}
                      />
                      <ListItemText primary={skill.skill_name} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </div>
            </div>
                <div style={{ marginTop: "1rem" }}>
                    <div>
                      <Button
                        variant="outlined"
                        className="mr-1 outlined-button"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleExportClick}
                        className="filled-button"
                      >
                        Export
                      </Button>
                    </div>
                 </div>
            </div>
          </CardContent>
      </div>
    </div>
  );
};
export default SkillReport;
