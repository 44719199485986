import axios from 'axios';
import roleService from '../roles/roleService';

const baseURL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth`;


const authService = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const login = async (username, password) => {
  try {
    const response = await authService.post('/login', {
      username,
      password,
    });

    const token = response.data.token;
    const userId= response.data.userId;
    const userRole= response.data.userRole;
    const users = await roleService.getUsersByRole(token, userRole);

    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('userId', userId)
    localStorage.setItem('tab_id', users[0].tab_id)
    localStorage.setItem('userRole', userRole)

    return { success: true, data: response.data };
  } catch (error) {
    console.error('Login failed:', error.response.data.message);
    return { success: false, error: 'Invalid credentials. Please try again.' };
  }
};

export const signUp = async (userData) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    const response = await authService.post('/employees/add', userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { success: true, data: response.data };
  } catch (error) {
    console.error('Signup failed:', error.response.data.message);
    return { success: false, error: 'Signup failed. Please try again.' };
  }
};

export const logout = () => {
  localStorage.clear()
};