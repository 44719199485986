import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const StageList = ({ stages, handleOpenUpdateDialog, handleDelete, handleReplicate }) => {
  const navigate = useNavigate();

  const handleMissionClick = (stageId, skillsId) => {
    navigate(`${stageId}/mission`);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Stage ID</th>
          <th>Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {Array.isArray(stages.stages) && stages.stages.map((stage) => (
          <tr key={stage.stage_id}>
            <td>{stage.stage_id}</td>
            <td>{stage.stage_name}</td>
            <td>
              <Button variant="contained" color="primary" className="mr-2 button-two" onClick={() => handleOpenUpdateDialog(stage)}>
                Update
              </Button>
              <Button variant="contained" color="primary" className="mr-2 button-two" onClick={() => handleMissionClick(stage.stage_id)}>
                Mission/Battle
              </Button>
              <Button variant="contained" color="secondary" className="mr-2 button-two" onClick={() => handleDelete(stage.stage_id)}>
                Delete
              </Button>
              <Button variant="contained" color="primary" className="mr-2 button-two" onClick={() => handleReplicate(stage.stage_id)}>
                Replicate
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StageList;
