import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./ExperimentReport.module.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import Report from "./Report";
import { useLocalStorage } from "@uidotdev/usehooks";

const ExperimentReport = () => {
  const [token,setToken] = useLocalStorage('token',null);
  const [experiment, setExperiment] = useState([]);
  const [filter, setFilter] = useState("");
  const [reports, setReports] = useState([]);
  const [selectedExperiment, setSelectedExperiment] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [attemptId, setAttemptId] = useState();
  const [userId, setUserId] = useState();
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(9);

  const fetchExperiments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setExperiment(response.data.data);
      } else {
        console.error("Error fetching experiments:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching experiments:", error);
    }
  };

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/experiment-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleExperimentChange = (event) => {
    setSelectedExperiment(event.target.value);
  };

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const handleReset = () => {
    setFilter("");
    setSelectedExperiment("");
  };

  useEffect(() => {
    fetchReports();
    fetchExperiments();
  }, [selectedExperiment]);

  const handleExportClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-experiment`,
        {
          name: filter,
          experiment_id: selectedExperiment,
        },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "experimentAttempts.xlsx");
      document.body.appendChild(link);
      link.click();

      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  const handleSingleExport = (attempt_id, user_id) => {
    setShowReport(true);
    setAttemptId(attempt_id);
    setUserId(user_id);
  };

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    )
  }

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <>
      {showReport ? (
        <Report
          attemptId={attemptId}
          userId={userId}
          setShowReport={setShowReport}
        />
      ) : (
        <div className="container-fluid mt-3 p-0">
          <div className="align-center-all full-height">
              <CardContent>
                <div className="filters">
                <div className="sub-title">Experiment Report</div>
                  <div
                    style=
                    {{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <div className="fieldContainer">
                    <div className="leftFields">
                    {/* <FormControl
                    fullWidth
                    className="mb-3 customTextfield"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      width: '32.051vw'
                    }}
                    >
                      <InputLabel id="experiment-label">
                        Labs
                      </InputLabel>
                      <Select
                        labelId="experiment-label"
                        id="experiment-select"
                        label="Experiment"
                      >
                        <MenuItem
                        >
                        </MenuItem>
                      </Select>
                    </FormControl> */}
                      <FormControl
                      fullWidth
                      className="mb-3 customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                        width: '32.051vw'
                      }}
                      >
                        <InputLabel id="experiment-label">
                          Experiment
                        </InputLabel>
                        <Select
                          labelId="experiment-label"
                          id="experiment-select"
                          value={selectedExperiment}
                          onChange={handleExperimentChange}
                          label="Experiment"
                        >
                          {experiment?.map((experimentItem) => (
                            <MenuItem
                              key={experimentItem.experiment_id}
                              value={experimentItem.experiment_id}
                            >
                              {experimentItem.experiment_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="rightFields">
                      {/* <TextField
                        label="Name"
                        variant="outlined"
                        value={filter}
                        fullWidth
                        onChange={handleInputChange}
                        placeholder="Enter Name"
                        className="mb-3 customTextfield"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                          width: '32.051vw'
                        }}
                      /> */}
                      <TextField
                        label="Name"
                        variant="outlined"
                        value={filter}
                        fullWidth
                        onChange={handleInputChange}
                        placeholder="Enter Name"
                        className="mb-3 customTextfield"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                          width: '32.051vw'
                        }}
                      />
                      </div>
                    </div>
                      <div>
                        <Button
                          variant="outlined"
                          className="mr-1 outlined-button"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleExportClick}
                          className="filled-button"
                        >
                          Export
                        </Button>
                      </div>
                  </div>
                </div>

                <table className="table mt-5">
                  <thead className="table-head">
                    <tr>
                      <th>S. No</th>
                      <th>Name</th>
                      <th>Class</th>
                      <th>Earned/Total Score</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports
                      ?.filter(
                        (report) =>
                          report.name
                            ?.toLowerCase()
                            .includes(filter?.toLowerCase()) &&
                          report.experiment_id
                            ?.toString()
                            .includes(selectedExperiment)
                      )
                      .map((filteredReport) => (
                        <tr key={filteredReport.attempt_id}>
                          <td>{filteredReport.attempt_id}</td>
                          <td>{filteredReport.name}</td>
                          <td>{filteredReport.user_class}</td>
                          <td>{`${filteredReport.total_correct}/${filteredReport.total_questions}`}</td>
                          <td>
                            {formatDate(filteredReport.start_time)}
                          </td>
                          <td>
                          {formatDate(filteredReport.end_time)}
                          </td>
                          <td>
                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-2 button-two"
                              onClick={() =>
                                handleSingleExport(
                                  filteredReport.attempt_id,
                                  filteredReport.user_id
                                )
                              }
                            >
                              Single Export
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardContent>
          </div>
        </div>
      )}
    </>
  );
};

export default ExperimentReport;
