import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import axios from "axios";
import { useLocalStorage } from "@uidotdev/usehooks";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({
    role: "",
    name: "",
    email: "",
    phone: "",
    dob: "",
  });
  const [token,setToken] = useLocalStorage('token',null);

  const fetchProfileData = () => {
    const userId = localStorage.getItem("userId");

    axios
      .get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        // console.log(new Date(data.data.dob));
        if (data.message === "Data fetched successfully") {
          let userDate=new Date(data.data.dob)
          userDate.setHours(userDate.getHours()+5);
          userDate.setMinutes(userDate.getMinutes()+30)
          let userData={
            ...data.data,
            dob:userDate
          }
          setProfileData(userData);
          setUpdatedProfile(filterExcludedProperties(userData));
        } else {
          console.error("Error fetching data:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUpdateProfile = () => {
    const userId = localStorage.getItem("userId");

    const mappedProfile = {
      role: updatedProfile.role,
      name: updatedProfile.emp_name,
      email: updatedProfile.email,
      phone: updatedProfile.phone,
      dob: updatedProfile.dob,
    };
    console.log(mappedProfile);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/update/${userId}`,
        mappedProfile,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.message === "Employee details updated successfully") {
          handleCloseDialog();
          fetchProfileData();
        } else {
          console.error("Error updating profile:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };

  const dateChangeHandler=(value)=>{
      // console.log(dayjs(value).fo  rmat("DD/MM/YYYY"));
      console.log(value);
      // console.log();
      setUpdatedProfile(prev=>{
        return {
          ...prev,
          dob:dayjs(value).format("DD/MM/YYYY")
        }
      })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const filterExcludedProperties = (data) => {
    const excludedProperties = [
      "created_at",
      "updated_at",
      "password",
      "is_active",
      "emp_id",
    ];
    return Object.keys(data)
      .filter((property) => !excludedProperties.includes(property))
      .reduce((result, key) => {
        result[key] =
          key === "dob"
            ? new Date(data[key]).toISOString().slice(0, 10)
            : data[key];
        return result;
      }, {});
  };

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            {profileData ? (
              <>
                <div>
                  <Typography variant="h4" gutterBottom>
                    {profileData.emp_name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    {profileData.email}
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  {Object.keys(updatedProfile).map((property) => (
                    <Grid item xs={12} sm={6} key={property}>
                      <TextField
                        label={
                          property.charAt(0).toUpperCase() + property.slice(1)
                        }
                        name={property}
                        value={updatedProfile[property]}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        disabled
                      />
                    </Grid>
                  ))}
                </Grid>
                <Button variant="contained" color="primary" onClick={handleOpenDialog} className="mr-2 button-two">
                  Update Profile
                </Button>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Edit Profile</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      {Object.keys(updatedProfile).map((property) =>
                        {
                          // console.log(property);
                          // console.log(updatedProfile[property]);
                        return (property !== "dob" ? (
                          <Grid item xs={12} sm={6} key={property}>
                            <TextField
                              label={
                                property.charAt(0).toUpperCase() +
                                property.slice(1)
                              }
                              name={property}
                              value={updatedProfile[property]}
                              onChange={handleInputChange}
                              fullWidth
                              margin="normal"
                              disabled={property === "role"}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} key={property}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={
                                property.charAt(0).toUpperCase() +
                                property.slice(1)
                              }
                              value={dayjs(updatedProfile[property],"YYYY-MM-DD")}
                              format="YYYY-MM-DD"
                              onChange={dateChangeHandler}
                              selected
                            />
                          </LocalizationProvider>
                          </Grid>
                        ))
                      })}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseDialog}
                      color="error"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpdateProfile}
                      color="primary"
                      variant="contained"
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <Typography variant="body1">Loading...</Typography>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Profile;
