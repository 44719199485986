import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import StageList from "./StageList";
import AddUpdateDialog from "./AddUpdateDialog";
import service from "./stageService";
import { useParams } from "react-router-dom";
import War from "../war/War";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { useLocalStorage } from "@uidotdev/usehooks";

const Stages = () => {
  const { skillId } = useParams();
  const [stages, setStages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newStage, setNewStage] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [replicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [newSkillId, setNewSkillId] = useState("");
  const [newStageName, setNewStageName] = useState("");
  const [replicateStageId, setReplicateStageId] = useState(null);
  const [skills, setSkills] = useState([]);
  const [token,setToken] = useLocalStorage('token',null);

  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);

  useEffect(() => {
    fetchStages();
    fetchSkills();
  }, []);

  const fetchStages = async () => {
    try {
      
      const fetchedStages = await service.getStages(token, skillId);
      setStages(fetchedStages);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSkills(response.data.data || []);
    } catch (error) {
      console.error("Error fetching skills:", error);
      setSkills([]);
    }
  };

  useEffect(() => {
    fetchStages();
    fetchSkills();
  }, []);

  const handleUpdateStage = async () => {
    try {

      const success = await service.updateStage(token, newStage.stage_id, {
        name: newStage.stage_name,
        specializationId: newStage.specialization_id,
        skillId: skillId,
        description: newStage.description,
        order: newStage.stage_order,
      });
      console.log(success)

      if (success.status===200) {
        toast.success(`${success.data.message}`);
        fetchStages();
        handleCloseUpdateDialog();
      } else if(success.status===400){
        toast.error(`${success.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleOpenUpdateDialog = (stage) => {
    console.log(stage);
    setFormFields(Object.keys(stage));
    setNewStage(stage);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setNewStage({});
  };

  const handleMission = (stageId) => {
    console.log(`Mission for Stage ${stageId}`);
  };

  const handleDelete = async (stageId) => {
    try {
 

      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this stage?',
        buttons: [
          {
            label: 'Yes',
            style: { backgroundColor: '#d32f2f', color: 'white' },
            onClick: async () => {
              try {
                const success = await service.deleteStage(token, stageId);

                if (success) {
                  toast.success("Stage deleted successfully");
                  fetchStages();
                }
              } catch (error) {
                console.error("Error deleting stage: ", error);
                toast.error(`Error deleting stage: ${error.message}`);
              }
            },
          },
          {
            label: 'No',
            style: { backgroundColor: '#1976d2', color: 'white' },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error("Error deleting stage: ", error);
      toast.error(`Error deleting stage: ${error.message}`);
    }
  };

  const handleReplicate = (stageId) => {
    setReplicateDialogOpen(true);
    setReplicateStageId(stageId);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
    setNewSkillId("");
    setNewStageName("");
  };

  const handleReplicateConfirm = async () => {
    try {

      const success = await service.replicateStage(
        token,
        replicateStageId,
        newSkillId,
        newStageName,
        skillId
      );
  
      if (success) {
        toast.success("Stage replicated successfully");
        fetchStages();
        handleReplicateDialogClose();
      } else {
        toast.error("Failed to replicate stage.");
      }
    } catch (error) {
      console.error("Error replicating stage: ", error);
      toast.error(`Error replicating stage: ${error.response.data.message}`);
    }
  };
  const handleAdd = () => {
    setOpenDialog(true);
    setNewStage({});
  };

  const handleAddStage = async () => {
    try {

      const response = await service.addStage(token, skillId, {
        name: newStage.name,
        specializationId: newStage.specializationId,
        skillId: skillId,
        description: newStage.description,
        order: newStage.order,
      });
  
      if (response && response.status === 201) {
        toast.success(`${response.data.message}`);
        fetchStages();
        handleCloseDialog();
      } else if (response && response.status === 400) {
        toast.error(`${response.data.message}`);
      } else {
        toast.error('Failed to add stage. Please try again.');
      }
    } catch (error) {
      console.error("Error adding stage: ", error);
      toast.error(`${error.response.data.message}`);
    }
  };
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewStage({});
  };

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card elevation={3} className="col-md-12 p-3">
            <CardContent>
              <h2>Stages for Skill {skillId}</h2>
              <ToastContainer />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-2 button-two"
                  onClick={handleAdd}
                  style={{ float: "right" }}
                >
                  Add
                </Button>
              </div>
              <StageList
                stages={stages}
                handleOpenUpdateDialog={handleOpenUpdateDialog}
                handleMission={handleMission}
                handleDelete={handleDelete}
                handleReplicate={handleReplicate}
              />
              <AddUpdateDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                formFields={[
                  "name",
                  "order",
                  "description",
                  "specializationId",
                ]}
                newStage={newStage}
                setNewStage={setNewStage}
                handleSubmit={handleAddStage}
              />
              <AddUpdateDialog
                open={openUpdateDialog}
                handleClose={handleCloseUpdateDialog}
                formFields={formFields}
                newStage={newStage}
                setNewStage={setNewStage}
                handleSubmit={handleUpdateStage}
              />
              <Dialog
                open={replicateDialogOpen}
                onClose={handleReplicateDialogClose}
              >
                <DialogTitle>Replicate Stage</DialogTitle>
                <DialogContent>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="skill-select-label">Skill</InputLabel>
                  <Select
                    labelId="skill-select-label"
                    value={newSkillId}
                    onChange={(e) => setNewSkillId(e.target.value)}
                    label="Skill"
                  >
                    {skills.map((skill) => (
                      <MenuItem key={skill.skill_id} value={skill.skill_id}>
                        {skill.skill_name}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <TextField
                    label="New Stage Name"
                    value={newStageName}
                    onChange={(e) => setNewStageName(e.target.value)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleReplicateDialogClose} color="error" variant="contained">
                    Cancel
                  </Button>
                  <Button onClick={handleReplicateConfirm} color="primary" variant="contained">
                    Confirm Replication
                  </Button>
                </DialogActions>
              </Dialog>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}
      </div>
      <War />
    </div>
  );
};

export default Stages;
