import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./UserExperimentReport.module.css";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Autocomplete
} from "@mui/material";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { TablePagination } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";

const UserExperimentReport = () => {
    const [token,setToken] = useLocalStorage('token',null);
    const [experimentUsers, setExperimentUsers] = useState([]);
    const [experiments, setExperiments] = useState([]);
    const [selectedExperiment, setSelectedExperiment] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filteredExperimentUsers, setFilteredExperimentUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchExperiments();
    }, []);

    useEffect(() => {
        if (selectedExperiment) {
            fetchExperimentUsers(selectedExperiment.experiment_id);
        }
    }, [selectedExperiment]);

    useEffect(() => {
        if (selectedExperiment && selectedUser) {
            const filteredUsers = experimentUsers.filter(
                user =>
                    user.experiment_id === selectedExperiment.experiment_id &&
                    user.user_id === selectedUser.user_id
            );
            setFilteredExperimentUsers(filteredUsers);
        } else {
            setFilteredExperimentUsers([]);
        }
    }, [experimentUsers, selectedExperiment, selectedUser]);

    const fetchExperiments = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/all`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                setExperiments(response.data.data);
            } else {
                console.error("Error fetching experiments:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching experiments:", error);
        }
    };

    const fetchExperimentUsers = async (experimentId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/experiment-users/${experimentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                setExperimentUsers(response.data.data);
            } else {
                console.error("Error fetching experiment users:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching experiment users:", error);
        }
    };

    const handleExperimentChange = (event) => {
        const selectedExp = experiments.find(
            (exp) => exp.experiment_id === event.target.value
        );
        setSelectedExperiment(selectedExp);
        setSelectedUser(null); // Reset selected user when experiment changes
        setPage(0);
    };

    const handleUserChange = (event, value) => {
        setSelectedUser(value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (utcDateString) => {
        const testDate = new Date(utcDateString);
        testDate.setHours(testDate.getHours() + 5);
        testDate.setMinutes(testDate.getMinutes() + 30);
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        };
        return testDate.toLocaleDateString("en-GB", options);
    };

    return (
        <div className="container-fluid mt-5">
            <div className="align-center-all full-height">
                <Card elevation={3} className="col-md-12 p-3">
                    <CardContent>
                        <div className="filters">
                            <h2>User Experiment Report</h2>
                            <div className={styles.reportHeader}>
                                <div className={styles.fieldContainer}>
                                    <FormControl>
                                        <InputLabel id="experiment-label">Experiment</InputLabel>
                                        <Select
                                            labelId="experiment-label"
                                            id="experiment-select"
                                            value={selectedExperiment ? selectedExperiment.experiment_id : ""}
                                            onChange={handleExperimentChange}
                                            label="Experiment"
                                        >
                                            {experiments.map((experimentItem) => (
                                                <MenuItem
                                                    key={experimentItem.experiment_id}
                                                    value={experimentItem.experiment_id}
                                                >
                                                    {experimentItem.experiment_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            id="user-autocomplete"
                                            options={experimentUsers}
                                            getOptionLabel={(option) => option.username}
                                            value={selectedUser}
                                            onChange={handleUserChange}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Username" />
                                            )}
                                            disabled={!selectedExperiment}
                                        />
                                    </FormControl>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <div>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className="mr-1 button-two"
                                            onClick={() => {
                                                setSelectedExperiment(null);
                                                setSelectedUser(null);
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {selectedUser && (
                                <>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        component="div"
                                        count={filteredExperimentUsers.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>User ID</th>
                                                <th>Username</th>
                                                <th>Score</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredExperimentUsers
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((user) => (
                                                    <tr key={user.user_id}>
                                                        <td>{user.user_id}</td>
                                                        <td>{user.username}</td>
                                                        <td>
                                                            {user.total_correct}/{user.total_questions}
                                                        </td>
                                                        <td>{formatDate(user.created_at)}</td>
                                                        <td>
                                                            <Link
                                                                key={user.attempt_id}
                                                                to={`/labs/${user.user_id}/experiments/report/${user.attempt_id}`}
                                                            >
                                                                <Button style={{backgroundColor:"#1976D2", color:"white", textTransform:"capitalize"}}>
                                                                    Download
                                                                </Button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default UserExperimentReport;
