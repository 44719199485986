import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import { TablePagination } from '@mui/material'
import CardContent from "@mui/material/CardContent";
import { useLocalStorage } from "@uidotdev/usehooks";

const QrCode = () => {
    const [qrCode, setQrCode] = useState([]);
    const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
    const isAuthorized = userTabIds.includes(15);

    const [token, setToken] = useLocalStorage('token', null);

    const formatDate = (date) => {
        date = new Date(date);
        // Add 5 hours and 30 minutes
        // let ISTOffset = 5.5 * 60 * 60 * 1000; // in ms
        let ISTDate = new Date(date.getTime());

        let day = ISTDate.getDate();
        let month = ISTDate.getMonth() + 1;
        let year = ISTDate.getFullYear();

        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        let formattedDate = `${day}.${month}.${year}`;

        let hours = ISTDate.getHours();
        let minutes = ISTDate.getMinutes();
        let AmOrPm = hours >= 12 ? 'pm' : 'am';
        hours = (hours % 12) || 12; 
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let formattedTime = `${hours}:${minutes}${AmOrPm}`;
        return { time: formattedTime, date: formattedDate };
    };

    const fetchQrCode = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/qr-code/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setQrCode(response.data.data);
            } else {
                console.error("Error fetching qrCode: ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching qrCode: ", error);
        }
    };

    useEffect(() => {
        fetchQrCode();
    }, [token]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    }
    return (
        <div className="container-fluid" style={{ paddingTop: '4.5vw' }}>
            <div className="align-center-all full-height">
                {isAuthorized ? (
                    <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5vw' }}>
                                <h2>QR Code Leads</h2>
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[10, 20, 30]}
                                    count={qrCode.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                            <table className="table">
                                <thead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                                    <tr>
                                        <th style={{ borderTopLeftRadius: '4px' }}>S. No.</th>
                                        <th style={{ textAlign: 'left' }}>Name</th>
                                        <th style={{ width: '21.154vw' }}>Vendor</th>
                                        <th style={{ textAlign: 'left' }}>Email</th>
                                        <th>Mobile</th>
                                        <th style={{ borderTopRightRadius: '4px' }}>Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {qrCode.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((inquiry) => (
                                            <tr key={inquiry.qr_id}>
                                                <td>{inquiry.qr_id}</td>
                                                <td style={{ textAlign: 'left' }}>{inquiry.name}</td>
                                                <td>{inquiry.vendor}</td>
                                                <td style={{ textAlign: 'left' }}>{inquiry.email}</td>
                                                <td>{inquiry.phone}</td>
                                                <td>
                                                    <>
                                                        <p style={{ padding: '0', margin: '0' }}>{formatDate(inquiry.created_at).date}</p>
                                                        <p style={{ padding: '0', margin: '0' }}>{formatDate(inquiry.created_at).time}</p>
                                                    </>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                ) : (
                    <h2>You are unauthorized to access this page.</h2>
                )}
            </div>
        </div>
    );
};

export default QrCode;