import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/role`;

const roleService = {
  getRoles: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data.filter((role) => role.is_active === 1);
    } catch (error) {
      console.error("Error fetching roles:", error.response.data);
      throw error;
    }
  },

  createRole: async (token, newRole) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/add`, newRole, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating role:", error.response.data);
      throw error;
    }
  },

  updateRole : async (token, roleId, tabs) => {
    try {
      console.log(tabs);
      const response = await axios.put(`${API_BASE_URL}/update/${roleId}`, {tabIds: tabs} , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response)
      return response.data;      
    } catch (error) {
      throw error;
    }
  },

  deleteRole: async (token, roleId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/delete/${roleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting role:", error.response.data);
      throw error;
    }
  },
  
  getUsersByRole: async (token, roleName) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/all/${roleName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching users by role:", error.response.data);
      throw error;
    }
  },

};


export default roleService;
