import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const Loader = ({height=90,paddingLeft=0,paddingRight=0,marginRight=0,marginLeft=0}) => {
  const levelColors = {
    1: "#44ccff",
    2: "#7FFF00", // Level 2 color = GREEN
    3: "#FFFF00", // Level 3 color = YELLOW
    4: "#CC0000", // Level 4 color = RED
    5: "#FF9900", // Level 5 color = #FF9900
  };

  const level=Number(localStorage.getItem("level"))||1;
  // console.log(levelColors[level]);
  return (
    <Box sx={{
    
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `${height}vh`,
    width:'100%',
    paddingLeft:`${paddingLeft}rem`,
    paddingRight:`${paddingRight}rem`,
    marginLeft:``
      }}>
      <CircularProgress size="8vw"  />
      </Box>
  )
}

export default Loader