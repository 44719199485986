import React from 'react';
import { Button, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import navLinks from '../navLinks/Navlinks'; // Ensure this path is correct
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  const adminSection = navLinks.find(link => link.tag === "Admin");
  const adminTabs = adminSection ? adminSection.tabs : [];

  const leadsSection = navLinks.find(link => link.tag === "Lead Management System");
  const leadTabs = leadsSection ? leadsSection.tabs : [];

  const ContentSection = navLinks.find(link => link.tag === "Content Management System");
  const contentTabs = ContentSection ? ContentSection.tabs : [];

  return (
    <div className="container-fluid mt-4">
      <div className="content-wrapper">
          <div className="d-flex">
            <div className="page-title">Dashboard</div>
          </div>
          <div className='subtitle'>Admin</div>
          <div className='dashboard-card-container'>
            {adminTabs.map((tab) => (
              <div key={tab.id}>
                <div onClick={() => handleNavigation(tab.route)} className='dashboard-card'>
                  <div className='card-title'>
                    {tab.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='subtitle'>Lead Management System</div>
          <div className='dashboard-card-container'>
            {leadTabs.map((tab) => (
              <div key={tab.id}>
                <div onClick={() => handleNavigation(tab.route)} className='dashboard-card'>
                  <div className='card-title'>
                    {tab.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='subtitle'>Content Manangement System</div>
          <div className='dashboard-card-container'>
            {contentTabs.map((tab) => (
              <div key={tab.id}>
                <div onClick={() => handleNavigation(tab.route)} className='dashboard-card'>
                  <div className='card-title'>
                    {tab.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
};

export default Dashboard;