import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  makeStyles,
  MenuItem,
  Dialog,
  Modal,
  InputAdornment,
  IconButton,
  TablePagination,
  TableRow,
  TableCell,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./Institute.module.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useLocalStorage } from "@uidotdev/usehooks";

const instituteList = ["School", "College", "NGO", "Institute"];

const Institute = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [aState, setAState] = useState("");
  const [pincode, setPincode] = useState("");
  const [token,setToken] = useLocalStorage('token',null);
  const [error, setError] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [country, setCountry] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [instituteData, setInstituteData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [instId,setInstId]=useState(-1)
  const [isUpdate, setIsUpdate] = useState(false);
  const [isdelete,setIsDelete]=useState(false)

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedResults = filteredData.slice(startIndex, endIndex);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

  const clearFields=()=>{
    setName("")
    setEmail("")
    setMobile("")
    setPassword("")
    setCPassword("")
    setAddress("")
    setAState("")
    setCity("")
    setPincode("")
    setInstId(-1)
    setInstituteType("")
    setCountry("")
  }

  const getAllInstitutes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data)
      setInstituteData(response.data.data);
    } catch (error) {
      toast.error("Error occured while fetching institute Data");
      console.error("Error occured while fetching institute Data", error);
    }
  };

  useEffect(() => {
    getAllInstitutes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobile.length !== 10) {
      setError("Invalid Mobile number");
      toast.error("Invalid Mobile number")
      return;
    } else {
      setError("");
    }
    // console.log(
    //   name,
    //   email,
    //   mobile,
    //   instituteType,
    //   password,
    //   cPassword,
    //   address,
    //   city,
    //   aState,
    //   pincode
    // );

    if (password !== cPassword) {
      toast.error("Password and Re-Enter Password do not Match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/add`,
        {
          name: name,
          email: email,
          mobile: mobile,
          institute_type: instituteType,
          password: password,
          address: address,
          city: city,
          state: aState,
          country:country,
          pincode: pincode,
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Institute Registered Sucessfull");
        clearFields()
        getAllInstitutes()
      } else {
        toast.error("Error Registering Institute", response.data.message);
      }
    } catch (error) {
      toast.error("Error Registering Institute", error);
    }
  };

  const filterData = (query) => {
    if (query.length === 0) {
      setFilteredData(instituteData);
      return;
    }
    const temp = instituteData.filter(
      (v) =>
        v.institute_name.toLowerCase().includes(query.toLowerCase()) ||
        v.institute_email.toLowerCase().includes(query.toLowerCase())
    );
    console.log(temp);
    setFilteredData(temp);
  };

  // console.log(searchQuery);

  useEffect(() => {
    filterData(searchQuery);
  }, [searchQuery, instituteData]);

  const onUpdateClick = (inst) => {
    setIsUpdate(true);
    console.log(inst);
    setName(inst.institute_name);
    setEmail(inst.institute_email);
    setMobile(inst.institute_mobile_no);
    setInstituteType(inst.institute_type);
    setAddress(inst.institute_address);
    setCountry(inst.country);
    setCity(inst.institute_city);
    setAState(inst.institute_state);
    setPincode(inst.institute_pincode);
    setInstId(inst.institute_id)
    setOpen(true);
  };

  const updateSubmitHandler=async(e)=>{
    e.preventDefault();
    if (mobile.length !== 10) {
      setError("Invalid Mobile number");
      toast.error("Invalid Mobile number")
      return;
    } else {
      setError("");
    }
    // console.log(
    //   name,
    //   email,
    //   mobile,
    //   instituteType,
    //   password,
    //   cPassword,
    //   address,
    //   city,
    //   aState,
    //   pincode
    // );

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/update`,
        {
          id:instId,
          name: name,
          email: email,
          mobile: mobile,
          institute_type: instituteType,
          address: address,
          city: city,
          state: aState,
          country:country,
          pincode: pincode,
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Institute Data updated Sucessfullt");
        clearFields()
        getAllInstitutes()
        
      } else {
        toast.error("Error Updating Institute Data", response.data.message);
      }
    } catch (error) {
      toast.error("Error Updating Institute Data", error);
    }

  }

  const onDeleteClick=async(id)=>{
      try {
        const result=await axios.delete( `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/delete/${id}`);

        if (result.status===200) {
          toast.success("Institute data deleted sucessfully");
          getAllInstitutes();
          setIsDelete(false)
        }
      } catch (error) { 
        console.error(error.response.data.message)
        toast.error("Error deleting institute")
      }
  }
// console.log(page);
  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <h2 className="page-header">Manage Skills</h2>
            <ToastContainer />
            <div className={styles.inputContainer}>
              <div className={styles.textInputContainer}>
                <TextField
                  type="text"
                  placeholder="name, username, or email"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  className="mr-2 button-two"
                  style={{ height: "2.2rem" }}
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                >
                  Add
                </Button>
              </div>
              <TablePagination
                rowsPerPageOptions={[5,10, 20, 30]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(e) => {
                  console.log(e.target.value);
                  setRowsPerPage(parseInt(e.target.value, 10));
                  setPage(0);
                }}
              />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Institute ID</th>
                  <th>Ins Referall ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile No</th>
                  <th>Institute Type</th>
                  {/* <th>Address</th> */}
                  <th>City</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {displayedResults.map((inst, ind) => {
                  return (
                    <tr key={ind}>
                      <td style={{width:"2vw"}} >{inst.institute_id}</td>
                      <td style={{width:"5vw"}}  >{inst.institute_referral_id}</td>
                      <td style={{width:"20vw"}} >{inst.institute_name}</td>
                      <td   style={{width:"5vw"}}>{inst.institute_email}</td>
                      <td  style={{width:"5vw"}} >{inst.institute_mobile_no}</td>
                      <td   style={{width:"5vw"}}>{inst.institute_type}</td>
                      {/* <td  >{inst.institute_address}</td> */}
                      <td   style={{width:"5vw"}}>{inst.institute_city}</td>
                      <td className={styles.buttonsContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onUpdateClick(inst)}
                        >
                          Update
                        </Button>
                        <Button variant="contained" color="error"  onClick={()=>{setIsDelete(true); setInstId(inst.institute_id)}} >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </tbody>
            </table>
          </CardContent>

          <Dialog
            open={open}
            fullWidth
            onClose={() => {
              setOpen(false);
              setIsUpdate(false)
              clearFields()
            }}
          >
            <div className={styles.dialogContainer}>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ marginBottom: "2rem" }}
              >
                {!isUpdate?"Add Institute":"Update Institute Details"}
              </Typography>
              <form onSubmit={!isUpdate?handleSubmit:updateSubmitHandler} className={styles.mainForm}>
                <TextField
                  fullWidth
                  required
                  label="Name"
                  id="name"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                {!isUpdate && <TextField
                  fullWidth
                  required
                  label="Email"
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />}
                <TextField
                  fullWidth
                  required
                  label="Mobile no"
                  id="mobille"
                  type="number"
                  error={error.length !== 0}
                  color={error.length !== 0 ? "error" : "primary"}
                  helperText={error.length !== 0 ? error : ""}
                  name="mobile"
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  sx={{
                    input: {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      "&[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                    },
                  }}
                />
                <TextField
                  id="insitute-type"
                  select
                  label="Institute Type"
                  defaultValue=""
                  value={instituteType}
                  required
                  onChange={(e) => {
                    setInstituteType(e.target.value);
                  }}
                >
                  {instituteList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                {!isUpdate && (
                  <TextField
                    fullWidth
                    required
                    label="Enter Password"
                    id="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                )}
                {!isUpdate && (
                  <TextField
                    fullWidth
                    required
                    label="Re-Enter Password"
                    id="cpassword"
                    type="password"
                    name="cpassword"
                    value={cPassword}
                    onChange={(e) => {
                      setCPassword(e.target.value);
                    }}
                  />
                )}
                <TextField
                  fullWidth
                  label="Address"
                  id="address"
                  type="text"
                  name="address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
                <TextField
                  required
                  fullWidth
                  label="City"
                  id="city"
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
                <TextField
                  required
                  fullWidth
                  label="State"
                  id="state"
                  type="text"
                  name="state"
                  value={aState}
                  onChange={(e) => {
                    setAState(e.target.value);
                  }}
                />
                <TextField
                  required
                  fullWidth
                  label="Country"
                  id="country"
                  type="text"
                  name="state"
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                />
                <TextField
                  required
                  fullWidth
                  label="Pincode"
                  id="pincode"
                  type="number"
                  name="pincode"
                  value={pincode}
                  onChange={(e) => {
                    setPincode(e.target.value);
                  }}
                  sx={{
                    input: {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      "&[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                    },
                  }}
                />

                <CardActions className={styles.buttonContainer}>
                  <Button type="submit" variant="contained" color="primary">
                    {isUpdate?"Update":"Register"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpen(false);
                      setIsUpdate(false)
                    }}
                  >
                    Close
                  </Button>
                </CardActions>
              </form>
            </div>
          </Dialog>
          <Dialog open={isdelete} onClose={()=>{setIsDelete(false)}} >
            <DialogTitle>Are you sure you want to delete this institute ?</DialogTitle>
            <DialogActions>
              <Button variant="contained" color="error"  onClick={()=>onDeleteClick(instId)} >Yes</Button>
              <Button variant="contained"  color="primary" onClick={()=>{setIsDelete(false)}} >No</Button>
            </DialogActions>
          </Dialog>
        </Card>
      </div>
    </div>
  );
};

export default Institute;
