const navLink = [
  {
    tag: "Admin",
    tabs: [
      {
        id: 1,
        title: "Subscriptions",
        route: "/Subscriptions",
      },
      {
        id: 19,
        title: "Experiment Key",
        route: "/expkey",
      },
      {
        id: 21,
        title: "Report",
        route: "/report",
      },
      {
        id: 7,
        title: "Employee",
        route: "/employee",
      },
      {
        id: 8,
        title: "Roles",
        route: "/roles",
      }
    ]
  },
  {
    tag: "Lead Management System",
    tabs: [
      {
        id: 2,
        title: "Enquiries",
        route: "/inquiries",
      },
      {
        id: 14,
        title: "Bulk Login",
        route: "/bulk-login",
      },
      {
        id: 13,
        title: "User Cart",
        route: "/user-cart",
      },
      {
        id: 17,
        title: "Qr Code Leads",
        route: "/qr-code",
      },
      {
        id: 15,
        title: "Summer Camp Referrals",
        route: "/summer-referrals",
      },
      {
        id: 18,
        title: "Institute",
        route: "/institute",
      },
      {
        id: 16,
        title: "Finance",
        route: "/finance-tab",
      },
      {
        id: 22,
        title: "Success Stories",
        route: "/testimonials",
      },
    ]
  },
  {
    tag: "Content Management System",
    tabs: [
      {
        id: 4,
        title: "Skills",
        route: "/skills",
      },
      {
        id: 3,
        title: "Labs",
        route: "/labs",
      },
      {
        id: 6,
        title: "Specialization",
        route: "/specialization",
      },
      {
        id: 22,
        title: "Blogs",
        route: "/blogs",
      },
    ]
  },
  {
    id: 5,
    title: "View Profile",
    route: "/profile",
  },
  {
    id: 9,
    title: "Experiment Report",
    route: "/experiment-report"
  },
  {
    id: 10,
    title: "Battle Report",
    route: "/battle-report",
  },
  {
    id: 11,
    title: "War Report",
    route: "/war-report",
  },
  {
    id: 12,
    title: "Skills Report",
    route: "/skills-report",
  },
  {
    id: 20,
    title: "User Experiment Report",
    route: "/user-exp-report",
  },
];

export default navLink;
