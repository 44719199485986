import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
} from "@mui/icons-material";
import React, { useCallback } from "react";
import styles from "./Toolbar.module.css";
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  Highlighter,
  Italic,
  LinkIcon,
  List,
  ListOrdered,
  Strikethrough,
  Underline,
  Unlink,
} from "lucide-react";

const Toolbar = ({ editor, content }) => {

  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) {
    return null;
  }



  return (
    <div className={styles.toolBarComponent}>
      {/* <button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>
          H1
        </button>
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
          H2
        </button>
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
          H3
        </button> */}
      {/* <button onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive('paragraph') ? 'is-active' : ''}>
          Paragraph
        </button> */}
      <select
        onChange={(e) =>
          editor.chain().focus().setFontFamily(e.target.value).run()
        }
      >
        <option value="Arial">Arial</option>
        <option value="monospace">MonoSpace</option>
        <option value="serif">Serif</option>
        <option value="Mullish">Mullish</option>
        {/* <option value="Calibri">Calibri</option>
          <option value="Poppins">Poppins</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Canva Sans">Canva Sans</option>
          <option value="Palatino">Baskerville</option>
          <option value="Crimson Text">Crimson Text</option>
          <option value="Playfair Display">Playfair Display</option>
          <option value="source seri">source seri</option>
          <option value="Roboto Mono">Roboto Mono</option>
          <option value="Source Code pro">Source Code pro</option> */}
      </select>
      <div
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`${styles.button} ${
          editor.isActive("bold") ? styles["is-active"] : ""
        }`}
      >
        <Bold className={styles.icon} />
      </div>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`${styles.button} ${
          editor.isActive("italic") ? styles["is-active"] : ""
        }`}
      >
        <Italic className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={`${styles.button} ${
          editor.isActive("strike") ? styles["is-active"] : ""
        }`}
      >
        <Strikethrough className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={`${styles.button} ${
          editor.isActive("underline") ? styles["is-active"] : ""
        }`}
      >
        <Underline className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        className={`${styles.button} ${
          editor.isActive("highlight") ? styles["is-active"] : ""
        }`}
      >
        <Highlighter className={styles.icon} />
      </button>
      <button
        onClick={()=>editor.isActive("link")?editor.chain().focus().unsetLink().run():setLink()}
        className={`${styles.button} ${
          editor.isActive("link") ? styles["is-active"] : ""
        }`}
      >
        {!editor.isActive("link") ? (
          <LinkIcon className={styles.icon} />
        ) : (
          <Unlink className={styles.icon} />
        )}
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
        className={`${styles.button} ${
          editor.isActive({ textAlign: "left" }) ? styles["is-active"] : ""
        }`}
      >
        <AlignLeft className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
        className={`${styles.button} ${
          editor.isActive({ textAlign: "center" }) ? styles["is-active"] : ""
        }`}
      >
        <AlignCenter className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
        className={`${styles.button} ${
          editor.isActive({ textAlign: "right" }) ? styles["is-active"] : ""
        }`}
      >
        <AlignRight className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign("justify").run()}
        className={`${styles.button} ${
          editor.isActive({ textAlign: "justify" }) ? styles["is-active"] : ""
        }`}
      >
        <AlignJustify className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${styles.button} ${
          editor.isActive("bulletList") ? styles["is-active"] : ""
        }`}
      >
        <List className={styles.icon} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${styles.button} ${
          editor.isActive("orderedList") ? styles["is-active"] : ""
        }`}
      >
        <ListOrdered className={styles.icon} />
      </button>
    </div>
  );
};

export default Toolbar;
