import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  FormControlLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Checkbox,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { login } from "../authService";
import { useLocalStorage } from "@uidotdev/usehooks";
import styles from "./Login.module.css";
import SmartsaLogo from "../../assets/SmartsaLogo.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    secondary: {
      main:"#37446F"
    },
  },
});


const Login = ({}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [token, setToken] = useLocalStorage("token",null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // const token = localStorage.getItem('token');
    if (token) {
      // setIsLoggedIn(true)
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value);
    
    
    // console.log(name,value);
    
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setError(null);

      const { success, data, error } = await login(
        formData.email,
        formData.password
      );

      if (success) {
        console.log("Login successful:", data);
        // setIsLoggedIn(true)
        navigate("/dashboard");
      } else {
        console.error("Login failed:", error);
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <div className={styles.parentContainer}>
      <div style={{ width: "32.051vw", height: "22.564vw" }}>
        <div className={styles.mainContainer}>
          <div className="login-form">
            {/* <Card elevation={3}> */}
            <CardContent style={{ width: "32.051vw" }}>
              <Typography
                variant="h5"
                align="center"
                className={styles.stylesContainer}
                gutterBottom
                style={{
                  marginBottom: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={SmartsaLogo} />
                Admin Login
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    label="UserName"
                    fullWidth
                    value={formData.email}
                    className={styles.inputs}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth={true}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    sx={{ backgroundColor: "transparent" }}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          style={{ background: "none" }}
                          onClick={() => {
                            setShowPassword((prev) => !prev);
                          }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <FormControl fullWidth className="mt-3">
                  <FormControlLabel
                    sx={{
                      height: "0.833vw",
                      margin: "0",
                      fontSize: "0.897vw",
                      ".MuiFormControlLabel-label": {
                        fontSize: "0.897vw",
                      },
                    }}
                    control={
                      <Checkbox
                        value={rememberMe}
                        onChange={() => {
                          setRememberMe((prev) => !prev);
                        }}
                        sx={{ height: "0.833vw", width: "0.833vw",marginRight:"0.5vw" }}
                      />
                    }
                    label="Remember Me"
                  />
                </FormControl>
                <CardActions style={{ width: "100%", padding: "0" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={styles.button}
                    color="secondary"
          
                    disabled={!formData.email || !formData.password || loading}
                    fullWidth
                    style={{ marginTop: "0.5rem", textTransform: "capitalize" }}
                  >
                    {loading ? "Logging in..." : "Login"}
                  </Button>
                </CardActions>
                {error && (
                  <Typography variant="body2" color="error" align="center">
                    {error}
                  </Typography>
                )}
              </form>
            </CardContent>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </div>
    </ThemeProvider>
  );
};

export default Login;
