import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "axios";
import WarExercise from "./warExercise";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useLocalStorage } from "@uidotdev/usehooks";

const War = () => {
  const { skillId } = useParams();
  const [wars, setWars] = useState([]);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [formData, setFormData] = useState({ warId: "", warName: "" });
  const [insertedQuestionIds, setInsertedQuestionIds] = useState([]);
  const [isReplicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [replicateFormData, setReplicateFormData] = useState({
    newSkillId: "",
    newWarName: "",
  });
  const [currentWarId, setCurrentWarId] = useState(null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);

  useEffect(() => {
    fetchWars();
  }, [skillId]);

  const [token,setToken] = useLocalStorage('token',null);

  const fetchWars = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/all/${skillId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setWars(response.data.data);
      } else {
        console.error('Error fetching wars:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching wars:', error);
    }
  };

  const inputFields = [
    { label: "War ID", name: "war_id", type: "number", disabled: true },
    { label: "War Name", name: "war_name" },
    // { label: "Question Type", name: "questionType" },
    { label: "War Order", name: "war_order", type: "number" },
    { label: "Marks/Question", name: "marks_per_question", type: "number" },
    { label: "Negative Marks", name: "negative_marks", type: "number" },
    { label: "No. of Questions", name: "no_of_questions", type: "number" },
    { label: "Description", name: "description", type: "multiline" },
    { label: "Instructions", name: "instructions", type: "multiline" },
    { label: "Max Time", name: "max_time", type: "time" },
    { label: "Clear Score", name: "clear_score", type: "number" },
    { label: "Coin Type", name: "coin_type" },
    { label: "Specialization ID", name: "specialization_id", type: "number" },
  ];

  const handleAdd = () => {
    setFormData({ warId: "", warName: "" });
    setAddDialogOpen(true);
  };

  const handleEdit = (warId) => {
    const warToEdit = wars.find((war) => war.war_id === warId);
    console.log(warToEdit)

    setFormData({
      war_id: warToEdit.war_id,
      war_name: warToEdit.war_name,
      questionType: warToEdit.question_type||"lorem",
      war_order: warToEdit.war_order,
      marks_per_question: warToEdit.marks_per_question,
      negative_marks: warToEdit.negative_marks||0,
      no_of_questions: warToEdit.no_of_questions,
      description: warToEdit.description,
      instructions: warToEdit.instructions,
      max_time: warToEdit.max_time,
      coin_type: warToEdit.coin_type,
      clear_score: warToEdit.clear_score,
      specialization_id: warToEdit.specialization_id,
    });

    setAddDialogOpen(true);
  };

  const handleReplicate = (warId) => {
    setCurrentWarId(warId);
    setReplicateDialogOpen(true);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
  };

  const handleReplicateSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/war/${currentWarId}`,
        {
          newSkillId: replicateFormData.newSkillId,
          oldSkillId: skillId,
          newWarName: replicateFormData.newWarName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchWars();
        setReplicateDialogOpen(false);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleDelete = async (warId) => {
    try {
      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this war?',
        buttons: [
          {
            label: 'Yes',
            style: { backgroundColor: '#d32f2f', color: 'white' },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/delete/${warId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  fetchWars();
                  toast.success('War deleted successfully');
                } else {
                  console.error('Error deleting war:', response.data);
                }
              } catch (error) {
                toast.error(`Error deleting war: ${error.message}`);
              }
            },
          },
          {
            label: 'No',
            style: { backgroundColor: '#1976d2', color: 'white' },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      toast.error(`Error deleting war: ${error.message}`);
    }
  };

  const handleCloseDialog = () => {
    setInsertedQuestionIds([]);
    setAddDialogOpen(false);
  };

  const handleSave = async () => {
    try {
      const requiredFields = [
        'war_name',
        'war_order',
        // 'questionType',
        'marks_per_question',
        'no_of_questions',
        'description',
        'instructions',
        'max_time',
        'coin_type',
        'clear_score'
      ];
  
      const missingFields = requiredFields.filter((field) => !formData[field]);
  
      if (missingFields.length > 0) {
        toast.error(`Missing required fields: ${missingFields.join(', ')}`);
        return;
      }
  
      if (!isTimeValid(formData.max_time)) {
        toast.error('Invalid time format for Speed. Please use "hh:mm:ss".');
        return;
      }
  
      const apiUrl = formData.war_id
        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/update/${formData.war_id}`
        : `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/add`;
  
      const payload = {
        war_name: formData.war_name,
        war_order: formData.war_order,
        question_type: formData.questionType||"lorem",
        marks_per_question: formData.marks_per_question,
        negative_marks: formData.negative_marks || 0,
        no_of_questions: formData.no_of_questions,
        description: formData.description,
        instructions: formData.instructions,
        max_time: formData.max_time,
        coin_type: formData.coin_type,
        questionIds: insertedQuestionIds,
        clear_score: formData.clear_score,
        specialization_id: formData.specialization_id,
        skillId: skillId,
      };
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios[formData.war_id ? 'put' : 'post'](apiUrl, payload, config);
  
      if (response.status === 201 || response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchWars();
      } else {
        toast.error(`${response.data.message}`);
      }
  
      handleCloseDialog();
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isTimeValid = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
      </div>
    )
  }

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <h2>War Page</h2>
            <Button variant="contained" color="primary" onClick={handleAdd} style={{float:'right'}} className="button-two">
              Add
            </Button>
            <table className="table">
              <thead>
                <tr>
                  <th>War ID</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {wars.map((war) => (
                  <tr key={war.war_id}>
                    <td>{war.war_id}</td>
                    <td>{war.war_name}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(war.war_id)}
                        className="mr-2 button-two"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleReplicate(war.war_id)}
                        className="mr-2 button-two"
                      >
                        Replicate
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(war.war_id)}
                        className="button-two"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>

        <Dialog open={isAddDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {formData.war_id ? "Update War" : "Add War"}
          </DialogTitle>
          <DialogContent>
            <WarExercise
              onQuestionsAdded={(ids) => setInsertedQuestionIds(ids)}
              questionType={formData.questionType}
              warId={formData.war_id}
            />
            {inputFields.map((field) => (
              <TextField
                key={field.name}
                label={field.label}
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type={`${field.label.includes("Order")?"Number":"text"}`}
                disabled={field.disabled}
                error={
                  field.name === "max_time" && !isTimeValid(formData.max_time)
                }
                helperText={
                  field.name === "max_time" && !isTimeValid(formData.max_time)
                    ? 'Invalid time format. Please use "hh:mm:ss".'
                    : ""
                }
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isReplicateDialogOpen}
          onClose={handleReplicateDialogClose}
        >
          <DialogTitle>Replicate War</DialogTitle>
          <DialogContent>
            <TextField
              label="New Skill ID"
              name="newSkillId"
              value={replicateFormData.newSkillId}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newSkillId: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
            />
            <TextField
              label="New War Name"
              name="newWarName"
              value={replicateFormData.newWarName}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newWarName: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReplicateDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleReplicateSubmit} color="primary">
              Replicate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default War;