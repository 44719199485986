import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { useLocalStorage } from "@uidotdev/usehooks";
import { confirmAlert } from "react-confirm-alert";

const Specialization = () => {
  const [specializations, setSpecializations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [skillInputValue, setSkillInputValue] = useState([]);
  const [newSpecializationName, setNewSpecializationName] = useState("");
  const [newSpecializationFee, setNewSpecializationFee] = useState("");
  const [newDiscountedFee, setNewDiscountedFee] = useState("");
  const [newSpecializationOrder, setNewSpecializationOrder] = useState("");
  const [newSpecializationDescription, setNewSpecializationDescription] =
    useState("");
  const [newSpecializationPaymentLink, setNewSpecializationPaymentLink] =
    useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [token,setToken] = useLocalStorage('token',null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(6);

  const handleOpenDialog = async () => {
    await fetchSpecializations();
    await fetchSkills();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setNewSpecializationName("");
    setNewSpecializationFee("");
    setNewDiscountedFee("");
    setNewSpecializationOrder("");
    setNewSpecializationDescription("");
    setNewSpecializationPaymentLink("");
    setSelectedSkills([]);
    setSkillInputValue([]);
    setSelectedSpecialization(null);
    setOpenDialog(false);
  };

  const handleUpdateOrAdd = async () => {
    try {
      if (skillInputValue.indexOf(-1)!==-1) {
        toast.error("Please Select all values in selection box or remove them");
        return;
      }

      const apiUrl = selectedSpecialization
        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/specialization/update/${selectedSpecialization.specialization_id}`
        : `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/specialization/create`;

      const response = await axios[selectedSpecialization ? "put" : "post"](
        apiUrl,
        {
          specializationName: newSpecializationName,
          specializationFee: newSpecializationFee,
          discountedFee: newDiscountedFee,
          specializationOrder: newSpecializationOrder,
          description: newSpecializationDescription,
          paymentLink: newSpecializationPaymentLink,
          skillIds: skillInputValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        fetchSpecializations();

        toast.success("Data Saved Succesfully")

        const updatedSpecialization = {
          specialization_id:
            response.data.insertId || selectedSpecialization?.specialization_id,
          specialization_name: newSpecializationName,
          specialization_fee: newSpecializationFee,
          discounted_fee: newDiscountedFee,
          specialization_order: newSpecializationOrder,
          paymentLink: newSpecializationPaymentLink,
          description: newSpecializationDescription,
        };

        setSpecializations((prevSpecializations) => {
          const filteredSpecializations = prevSpecializations.filter(
            (s) =>
              s.specialization_id !== updatedSpecialization.specialization_id
          );
          return [...filteredSpecializations, updatedSpecialization];
        });

        handleCloseDialog();
      } else {
        toast.error(
          "Failed to create/update specialization:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const handleUpdate = (specialization) => {
    setSelectedSpecialization(specialization);
    fetchSkills();
    setOpenDialog(true);
  };

  const handleDelete = async (specializationId) => {
    try {
      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this Specialization?',
        buttons: [
          {
            label: 'Yes',
            style: { backgroundColor: '#d32f2f', color: 'white' },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/specialization/delete/${specializationId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  fetchSpecializations();
                  toast.success('Specialization deleted successfully');
                } else {
                  console.error('Error deleting Specialization:', response.data);
                }
              } catch (error) {
                toast.error(`Error deleting Specialization: ${error.message}`);
              }
            },
          },
          {
            label: 'No',
            style: { backgroundColor: '#1976d2', color: 'white' },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      toast.error(`Error deleting Specialization: ${error.message}`);
      toast.error(`Error deleting Specialization: ${error.message}`);
    }
  };

  console.log(selectedSkills);
  // const handleSkillCheckboxChange = (skillId) => {
  //   const updatedSkills = selectedSkills.includes(skillId)
  //     ? selectedSkills.filter((id) => id !== skillId)
  //     : [...selectedSkills, skillId];
  //   setSelectedSkills(updatedSkills);
  // };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        setSkills(response.data.data);
      } else {
        console.error("Failed to fetch skills:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const fetchSpecializations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/specialization/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSpecializations(response.data.data);
      } else {
        console.error(
          "Failed to fetch specializations:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const handleSkillChange = (e, index) => {
    console.log(e.target.value);

    setSkillInputValue((prev) => {
      const temp = [...prev];
      temp[index] = e.target.value;
      return temp;
    });
    // console.log(skillInputValue);
  };

  const onAddClick = () => {
    setSkillInputValue((prev) => {
      return [...prev, -1];
    });
  };

  const onRemoveClick = (index) => {
    if (skillInputValue.length === 1) {
      setSkillInputValue([]);
      return;
    }
    let temp = skillInputValue;
    temp = temp.filter((val, ind) => {
      // console.log(ind,index);

      return ind !== index;
    });

    setSkillInputValue(temp);
  };

  useEffect(() => {
    fetchSpecializations();
    if (selectedSpecialization) {
      setNewSpecializationName(
        selectedSpecialization.specialization_name || ""
      );
      setNewSpecializationFee(selectedSpecialization.specialization_fee || "");
      setNewDiscountedFee(selectedSpecialization.discounted_fee || "");
      setNewSpecializationOrder(
        selectedSpecialization.specialization_order || ""
      );
      setNewSpecializationDescription(selectedSpecialization.description || "");
      setNewSpecializationPaymentLink(
        selectedSpecialization.payment_link || ""
      );
      setSkillInputValue(JSON.parse(selectedSpecialization.skill_ids) || []);
    } else {
      setNewSpecializationName("");
      setNewSpecializationFee("");
      setNewDiscountedFee("");
      setNewSpecializationOrder("");
      setNewSpecializationDescription("");
      setNewSpecializationPaymentLink("");
      setSelectedSkills([]);
    }
  }, [openDialog, selectedSpecialization]);

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card elevation={3} className="col-md-12 p-3">
            <CardContent>
              <h2>Specialization</h2>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                  className="mr-2"
                  style={{ float: "right" }}
                >
                  Add
                </Button>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Fee</th>
                    <th>Discounted Fee</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {specializations.map((specialization, index) => (
                    <tr
                      key={specialization.specialization_id || `${index + 1}`}
                    >
                      <td>{specialization.specialization_id}</td>
                      <td>{specialization.specialization_name}</td>
                      <td>{specialization.specialization_fee}</td>
                      <td>{specialization.discounted_fee}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdate(specialization)}
                          className="mr-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            handleDelete(specialization.specialization_id)
                          }
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                  {selectedSpecialization ? "Update" : "Add"} New Specialization
                </DialogTitle>
                <form className="m-2">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Name"
                        fullWidth
                        value={newSpecializationName}
                        onChange={(e) =>
                          setNewSpecializationName(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Fee"
                        type="number"
                        fullWidth
                        value={newSpecializationFee}
                        onChange={(e) =>
                          setNewSpecializationFee(e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Discounted Fee"
                        type="number"
                        fullWidth
                        value={newDiscountedFee}
                        onChange={(e) => setNewDiscountedFee(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Order"
                        type="number"
                        fullWidth
                        value={newSpecializationOrder}
                        onChange={(e) =>
                          setNewSpecializationOrder(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextareaAutosize
                        placeholder="Description"
                        value={newSpecializationDescription}
                        onChange={(e) =>
                          setNewSpecializationDescription(e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Payment Link"
                        fullWidth
                        value={newSpecializationPaymentLink}
                        onChange={(e) =>
                          setNewSpecializationPaymentLink(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {skillInputValue.map((val, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "1rem",
                              marginBottom: "1rem",
                            }}
                            key={index}
                          >
                            <TextField
                              select
                              // value={val}
                              value={skillInputValue[index]}
                              fullWidth
                              onChange={(e, val) => {
                                handleSkillChange(e, index);
                              }}
                            >
                              <MenuItem key={-1} value={-1}></MenuItem>
                              {skills.map((val, ind) => {
                                return (
                                  <MenuItem key={ind} value={val.skill_id}>
                                    {val.skill_name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                            <Button
                              onClick={() => {
                                onRemoveClick(index);
                              }}
                              variant="contained"
                              color="error"
                            >
                              Remove
                            </Button>
                          </div>
                        );
                      })}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onAddClick}
                      >
                        Add Skill
                      </Button>
                    </Grid>
                  </Grid>
                  <div style={{ float: "right" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateOrAdd}
                      className="mr-2"
                    >
                      {selectedSpecialization ? "Update" : "Add"} Specialization
                    </Button>
                    <Button variant="contained" onClick={handleCloseDialog}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </Dialog>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}
      </div>
    </div>
  );
};

export default Specialization;
