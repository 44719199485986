import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import styles from "./testimonials.module.css";
import TextEditor from "../Components/TextEditor/TextEditor";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import WarningIcon from "../assets/Alert.svg";

const Testimonials = () => {
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const [inputData, setInputData] = useState({
    t_name: "",
    t_class: "",
    t_image_url: "",
    t_description: "",
  });
  const [selectedId, setSelectedId] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const onWarningOpen = (id) => {
    setOpenWarning(true);
    setToDelete(id);
  };

  const onWarningClose = () => {
    setOpenWarning(false);
    setToDelete("");
  };

  const onUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/testimonials/update/${selectedId}`,
        {
          t_name: inputData.t_name,
          t_class: inputData.t_class,
          t_image_url: inputData.t_image_url,
          t_description: inputData.t_description,
        }
      );

      if (response.status === 200) {
        toast.success("Testimonial Updated Sucessfully");
        handleCloseDialog();
        getAllTestimonials();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error Updating Testimonial");
    }
  };

  const onAddClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/testimonials/add`,
        {
          t_name: inputData.t_name,
          t_class: inputData.t_class,
          t_image_url: inputData.t_image_url,
          t_description: inputData.t_description,
        }
      );

      if (response.status === 200) {
        toast.success("Testimonial Updated Sucessfully");
        handleCloseDialog();
        getAllTestimonials();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error Updating Testimonial");
    }
  };

  const handleClickEdit = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/testimonials/data/${id}`
      );

      if (response.status === 200) {
        const testi = response.data.data;
        // console.log(testi);

        setInputData({
          t_name: testi?.t_name,
          t_class: testi?.class,
          t_image_url: testi?.image_url,
          t_description: testi?.description,
        });
        setSelectedId(testi?.testimonial_id);
        setOpenEditDialog(true);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Error Fetching Skill Data to Update",
        error.response.data.message
      );
    }
  };

  const handleClickDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/testimonials/delete/${toDelete}`
      );

      if (response.status === 200) {
        toast.success("Testimonial Deleted Sucessfully");
        getAllTestimonials();
        setOpenWarning(false)
      }
    } catch (error) {
      console.error(error);
      toast.error("Error Deleting Testimonial");
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setOpenEditDialog(false);
    setInputData({
      t_name: "",
      t_class: "",
      t_image_url: "",
      t_description: "",
    });
    setSelectedId("");
  };

  const InputChangeHandler = (e) => {
    const name = e.target.name;
    setInputData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const getAllTestimonials = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/testimonials/all`
      );

      if (response.status === 200) {
        setTestimonials(response.data.data);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllTestimonials();
  }, []);

  const onTestimonialDescriptionChange = (value) => {
    setInputData((prev) => {
      return {
        ...prev,
        t_description: value,
      };
    });
  };

  return (
    <div className="page-wrapper">
      <div className="top-div">
        <div className="page-header">Success Stories</div>
        <Button className="filled-button" onClick={handleClick}>
          Add
        </Button>
      </div>
      <table className="mainTable">
        <thead className="tableHead">
          <tr>
            <th>S. No.</th>
            <th>Name</th>
            <th>Class</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {testimonials.map((testimonial) => (
            <tr className={styles.tableBody} id={testimonial.testimonial_id}>
              <td>{testimonial.testimonial_id}</td>
              <td>{testimonial.t_name}</td>
              <td>{testimonial.class}</td>
              <td>
                <div className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#D1F4DE",
                      height: "2.051vw",
                      width: "7.179vw",
                      color: "black",
                      textTransform: "capitalize",
                      padding: "6px 10px",
                      marginRight: "1rem",
                    }}
                    onClick={(e) => {
                      handleClickEdit(testimonial.testimonial_id);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "2.051vw",
                      width: "7.179vw",
                      color: "black",
                      backgroundColor: "#FFD6D2",
                      textTransform: "capitalize",
                    }}
                    onClick={(e) => {
                      onWarningOpen(testimonial.testimonial_id);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontSize: "1.154vw", fontWeight: "600" }}>
          Add New Success Story
        </DialogTitle>
        <DialogContent>
          <div className="dialog-fields">
            <TextField
              className="customTextfield"
              type="text"
              label="Name"
              name="t_name"
              value={inputData.t_name}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
              onChange={InputChangeHandler}
            />
            <TextField
              className="customTextfield"
              type="text"
              label="Class"
              name="t_class"
              value={inputData.t_class}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
              onChange={InputChangeHandler}
            />
            <TextField
              className="customTextfield"
              type="text"
              label="Image Url"
              name="t_image_url"
              value={inputData.t_image_url}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
              onChange={InputChangeHandler}
            />
          </div>
          <div>
            <TextEditor
              propsContent={inputData.t_description}
              propPlaceholder={"Short Description...."}
              onChangeContent={onTestimonialDescriptionChange}
            />
          </div>
          <div className="dialog-actions-box">
            <Button className="outlined-button" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button className="filled-button" onClick={onAddClick}>
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontSize: "1.154vw", fontWeight: "600" }}>
          Edit Success Story
        </DialogTitle>
        <DialogContent>
          <div className="dialog-fields">
            <TextField
              className="customTextfield"
              type="text"
              label="Name"
              name="t_name"
              variant="outlined"
              value={inputData.t_name}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
              onChange={InputChangeHandler}
            />
            <TextField
              className="customTextfield"
              type="text"
              label="Class"
              name="t_class"
              value={inputData.t_class}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
              onChange={InputChangeHandler}
            />
            <TextField
              className="customTextfield"
              type="text"
              label="Image Url"
              name="t_image_url"
              variant="outlined"
              value={inputData.t_image_url}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
              onChange={InputChangeHandler}
            />
          </div>
          <div>
            <TextEditor
              propsContent={inputData.t_description}
              propPlaceholder={"Short Description...."}
              onChangeContent={onTestimonialDescriptionChange}
            />
          </div>
          <div className="dialog-actions-box">
            <Button className="outlined-button" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button className="filled-button" onClick={onUpdateSubmit}>
              Update
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openWarning} onClose={onWarningClose} >
        <DialogContent className={styles.warningDialogContent}>
          <div className={styles.warningIconContainer}>
            <img src={WarningIcon} />
          </div>
          <div className={styles.warningTextContainer}>
            <h2>Are You Sure?</h2>
            <h5>You won't be able to revert this.</h5>
          </div>
          <DialogActions>
            <Button
              className="outlined-button"
              style={{ width: "7.692vw", height: "2.308vw" }}
              onClick={() => {
                setOpenWarning(false);
              }}
            >
              Cancel
            </Button>
            <Button className="filled-button" onClick={handleClickDelete}>
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Testimonials;
