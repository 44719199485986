import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}`;

const service = {
  getSpecificStages:async(token,skillIDs)=>{
    try {
      const encoded=encodeURIComponent(JSON.stringify(skillIDs))
      const response=await axios.get(`${API_BASE_URL}/stages/data`,{
        headers:{
          "Authorization":`Bearer ${token}`
        },
        params:{
        value:encoded
        }
        
      })

      if (response.status===200) {
        console.log(response.data.data);
        return response.data.data
      }else {
        console.log("Error Fetching Specific Skills",response.data.message);
        throw new Error("Error Fetching Specific Skills")
      }
      
    } catch (error) {
      console.log("Error Fetching Stages",error);
      throw error
    }
  },
  getStages: async (token, skillId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/stages/all/${skillId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data.data;
      } else {
        console.error('Error fetching stages: ', response.data.message);
        throw new Error('Error fetching stages');
      }
    } catch (error) {
      console.error('Error fetching stages: ', error);
      throw error;
    }
  },

  addStage: async (token, skillId, newStage) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/stages/add`, {
        stageName: newStage.name,
        specializationId: newStage.specializationId,
        skillId: skillId,
        description: newStage.description,
        stageOrder: newStage.order,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        console.log('Data added successfully');
        return response
      } else {
        console.error('Error adding stage: ', response.data.message);
        throw new Error('Error adding stage');
      }
    } catch (error) {
      console.error('Error adding stage: ', error);
      throw error;
    }
  },

  updateStage: async (token, stageId, updatedStage) => {
      const response = await axios.put(
        `${API_BASE_URL}/stages/update/${stageId}`,
        {
          stageName: updatedStage.name,
          specializationId: updatedStage.specializationId,
          skillId: updatedStage.skillId,
          description: updatedStage.description,
          stageOrder: updatedStage.order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response;
  },

  replicateStage: async (token, stageId, newSkillId, newStageName, skillId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/replicate/stage/${stageId}`, {
        newSkillId: newSkillId,
        newStageName: newStageName,
        oldSkillId: skillId,
        oldStageId:stageId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('Stage replicated successfully');
        return true;
      } else {
        console.error('Error replicating stage: ', response.data.message);
        throw new Error('Error replicating stage');
      }
    } catch (error) {
      console.error('Error replicating stage: ', error);
      throw error;
    }
  },

  deleteStage: async (token, stageId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/stages/delete/${stageId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('Data deleted successfully');
        return true;
      } else {
        console.error('Error deleting stage: ', response.data.message);
        throw new Error('Error deleting stage');
      }
    } catch (error) {
      console.error('Error deleting stage: ', error);
      throw error;
    }
  },
};

export default service;
