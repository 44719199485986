import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./BlogCategories.module.css";
import axios from "axios";

const BlogCategories = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCatId,setSelectedCatId]=useState("")
  const [categoryDetails, setCategoryDetails] = useState({
    name: "",
    order: "",
  });
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });
  const navigate = useNavigate();
  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/categories/all`
      );

      if ((response.status = 200)) {
        console.log(response.data.data);
        setCategoriesData(response.data.data);
      }
    } catch (error) {
      console.log(error.response.data.error);

      toast.error(error.response.data.message);
    }
  };

  const onArticleClick = (id) => {
    navigate(`details/${id}`);
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    setCategoryDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleDialogClose = () => {
    setSelectedCatId("")
    setIsOpen({
      add: false,
      update: false,
    });
    setCategoryDetails({
      name: "",
      order: "",
    });
  };

  const onAddClick=()=>{
    setIsOpen(prev=>({
      ...prev,
      add:true
    }))
  }

  
  const onEditClick=async(id)=>{
    try {
      const response =await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${id}`)
      console.log(response.data.data);
      
      setCategoryDetails({
        name:response.data.data.category_name,
        order:response.data.data.category_order
      })
      setSelectedCatId(response.data.data.category_id)
      setIsOpen(prev=>({
        ...prev,
        update:true
      }))
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const onCategoryAdd=async()=>{
    try {
     const response =await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category`,{
      name:categoryDetails.name,
      order:categoryDetails.order
     })

     if (response.status=200) {
        toast.success("Category Added Sucessfully");
        fetchAllCategories()
        handleDialogClose()
     }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error.response.data.error);
      
    }
  }

  const onCategoryUpdate=async()=>{
    try {
     const response =await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${selectedCatId}`,{
      name:categoryDetails.name,
      order:categoryDetails.order
     })

     if (response.status=200) {
        toast.success("Category Added Sucessfully");
        fetchAllCategories()
        handleDialogClose()
     }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error.response.data.error);
      
    }
  }

  const onCategoryDelete=async(id)=>{
    try {
      const response=await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${id}`)

      if (response.status===200) {
        toast.success("Category Deleted Sucessfully")
        fetchAllCategories()
      }

    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.error);
      
    }
  }

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <div className={styles.header}>
              <h2 style={{ marginRight: "1rem" }} className="page-header">
                Blog Categories
              </h2>
              <div className={styles.headerRight}>
                {/* <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                <Button
                  style={{
                    width: "15.385vw",
                    height: "2.564vw",
                    padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                    gap: "0.641vw",
                    borderRadius: "4px",
                    color: "#ffffff",
                    fontSize: "0.897vw",
                    backgroundColor: "#37446F",
                    boxShadow:
                      " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                  }}
                  onClick={()=>{
                    onAddClick()
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            <table className={styles.mainTable}>
              <thead className={styles.tableHead}>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "2vw",
                      width: "20%",
                    }}
                  >
                    S. No.
                  </th>
                  <th style={{ width: "50%", textAlign: "left" }}>
                    Category Name
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "5.128vw",
                      width: "fit-content",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {categoriesData.map((category) => (
                  <tr key={category?.category_id} className={styles.tableBody}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "3vw",
                        width: "20%",
                      }}
                    >
                      {category?.category_id}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {category?.category_name}
                    </td>
                    <td style={{ paddingLeft: "5.128vw" }}>
                      <div className={styles.buttonContainer}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {onEditClick(category?.category_id)}}
                          style={{
                            backgroundColor: "#D1F4DE",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            onArticleClick(category?.category_id);
                          }}
                          style={{
                            backgroundColor: "#CBEBF6",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          Article
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {onCategoryDelete(category?.category_id)}}
                          style={{
                            backgroundColor: "#FFD6D2",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>
        <Dialog open={isOpen.add || isOpen.update} onClose={handleDialogClose}>
          <DialogTitle>
            {isOpen.add ? "Add New CateGory" : "Update Category"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              type="text"
              label="Category Name"
              value={categoryDetails.name}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
            <TextField
              name="order"
              type="text"
              label="Category Order"
              value={categoryDetails.order}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                width: "15.385vw",
                height: "2.564vw",
                padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                gap: "0.641vw",
                borderRadius: "4px",
                color: "#ffffff",
                fontSize: "0.897vw",
                backgroundColor: "#37446F",
                gridColumn: "1/2",
                boxShadow:
                  " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
              }}
              onClick={() => {isOpen.add?onCategoryAdd():onCategoryUpdate()}}
            >
              {isOpen.add?"Add":"Update"}
            </Button>
            <Button
              // variant="outlined"
              style={{
                width: "15.385vw",
                height: "2.564vw",
                padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                gap: "0.641vw",
                borderRadius: "4px",
                color: "#000000",
                border: "2.03px solid #313C63",
                // borderImageSource: "linear-gradient(116.29deg, #313C63 3.44%, rgba(40, 49, 81, 0.95) 96.13%)",

                fontSize: "0.897vw",
                backgroundColor: "#ffffff",
                boxShadow:
                  " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
              }}
              onClick={() => {handleDialogClose()}}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default BlogCategories;
