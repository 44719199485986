import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styles from "./Blogs.module.css";
import axios from "axios";
import TextEditor from "../Components/TextEditor/TextEditor";

const Blogs = () => {
  const { catId } = useParams();
  const [isOpen, setIsOpen] = useState({
    add: false,
    update: false,
  });
  const [blogsData, setBlogsData] = useState([]);
  const [subTitle, setSubTitle] = useState([]);
  const [selectedBlogId,setSelectedBlogId]=useState("")

  const [blogsDetails, setBlogsDetails] = useState({
    title: "",
    order: "",
    author: "",
    imageURL: "",
    description: "",
    shortDescription: "",
    summary: "",
  });
  // const [blogDetails,setBlogDetails]=useState({

  // })
  const getAllBlogsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blogs/${catId}`
      );
      if ((response.status = 200)) {
        console.log(response.data.data);
        setBlogsData(response.data.data);
      }
    } catch (error) {
      console.log(error.response.data.error);

      toast.error(error.response.data.message);
    }
  };

  const addBlog=async()=>{
    try {
      const response=await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog`,{
          title:blogsDetails.title,
          order:blogsDetails.order,
          author:blogsDetails.author,
          imageURL:blogsDetails.imageURL,
          description:blogsDetails.description,
          shortDescription:blogsDetails.shortDescription,
          summary:blogsDetails.summary,
          subtitle:subTitle.map(sub=>sub.title),
          subtitleDesc:subTitle.map(sub=>sub.description),
          categoryId:catId
        }
      );

      if (response.status===200) {
        toast.success("Blog Added Sucessfully")
        getAllBlogsData()
        handleCloseAddDialog()
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)
      
    }
  }


  const editBlog=async(id)=>{
    try {
      const response=await axios.get( `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${id}`)
      console.log(response);
      setBlogsDetails({
        title: response.data.data.blog_title,
    order: response.data.data.article_order,
    author: response.data.data.author,
    imageURL: response.data.data.image_url,
    description: response.data.data.detailed_description||"",
    shortDescription: response.data.data.short_description||"",
    summary: response.data.data.summary,
      })
      setSelectedBlogId(response.data.data.blog_id)
      let temp=[]
      if (response.data.data.subtitle_titles) {
        const descriptionData=JSON.parse(response.data.data.subtitle_descriptions)
        temp=JSON.parse(response.data.data.subtitle_titles).map((val,ind)=>{
          return{
            title:val,
            description:descriptionData[ind]
          }
        })
      }
      console.log(response.data.data.subtitle_titles);
      
      setSubTitle(temp)
      setIsOpen(prev=>({
        ...prev,
        update:true
      }))
    } catch (error) {
      console.error(error);
      
    }
  }

  const updateBlog=async()=>{
    try {
      const response=await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${selectedBlogId}`,{
          title:blogsDetails.title,
          order:blogsDetails.order,
          author:blogsDetails.author,
          imageURL:blogsDetails.imageURL,
          description:blogsDetails.description,
          shortDescription:blogsDetails.shortDescription,
          summary:blogsDetails.summary,
          subtitle:subTitle.map(sub=>sub.title),
          subtitleDesc:subTitle.map(sub=>sub.description),
          categoryId:catId
        }
      );

      if (response.status===200) {
        toast.success("Blog Updated Sucessfully")
        getAllBlogsData()
        handleCloseAddDialog()
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)
      
    }
  }

  const deleteBlog=async(id)=>{
    try {
      const response=await axios.delete(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${id}`)

      if (response.status===200) {
        toast.success("Blog Deleted Sucessfully")
        getAllBlogsData()
        handleCloseAddDialog()
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)
    }
  }

  const onDescriptionChange = (value) => {
    setBlogsDetails((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const onShortDescriptionChange = (value) => {
    setBlogsDetails((prev) => ({
      ...prev,
      shortDescription: value,
    }));
  };

  const onSummaryChange = (value) => {
    setBlogsDetails((prev) => ({
      ...prev,
      summary: value,
    }));
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    console.log(name);
    
    setBlogsDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  useEffect(() => {
    getAllBlogsData();
  }, []);

  const handleCloseAddDialog = () => {
    setSelectedBlogId("")
    setIsOpen(
      {
        update:false,
        add:false
      }
    )
  };

  const onAddPress = () => {
    setIsOpen((prev) => ({
      ...prev,
      add: true,
    }));
  };
  console.log(blogsDetails.description);

  const addSubtitle = () => {
    setSubTitle((prev) => [
      ...prev,
      {
        title: "",
        description: "",
      },
    ]);
  };

  const onSubtitleChange = (e, index) => {
    console.log(subTitle);
    console.log(e.target.value,index);

    setSubTitle((prev) => {
      const temp = [...prev];
      temp[index].title = e.target.value;
      return temp;
    });
  };

  const onSubDescriptionChange = (value, index) => {
    console.log(subTitle);
    console.log(value,index);
    
    setSubTitle((prev) => {
      const temp = [...prev];
      temp[index].description = value;
      return temp;
    });
  };

  const onDeleteSubtitle=(ind)=>{
    console.log(ind);
    console.log(subTitle);
    
    setSubTitle((prev)=>{
      const temp= prev.filter((val,index)=>{
        return ind!==index
      })
      console.log(temp);
      
      return temp;
    })
  }

  return (
    <div className="container-fluid mt-5" style={{ height: "100vh" }}>
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <div className={styles.header}>
              <h2 style={{ marginRight: "1rem" }} className="page-header">
                Blogs
              </h2>
              <div className={styles.headerRight}>
                {/* <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                <Button
                  style={{
                    width: "15.385vw",
                    height: "2.564vw",
                    padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                    gap: "0.641vw",
                    borderRadius: "4px",
                    color: "#ffffff",
                    fontSize: "0.897vw",
                    backgroundColor: "#37446F",
                    boxShadow:
                      " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
                  }}
                  onClick={() => {
                    onAddPress();
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            {/* <ToastContainer /> */}
            <table className={styles.mainTable}>
              <thead className={styles.tableHead}>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "2vw",
                      width: "20%",
                    }}
                  >
                    S. No.
                  </th>
                  <th style={{ width: "50%", textAlign: "left" }}>Name</th>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "5.128vw",
                      width: "fit-content",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {blogsData.map((blog) => (
                  <tr key={blog?.blog_id} className={styles.tableBody}>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "3vw",
                        width: "20%",
                      }}
                    >
                      {blog?.blog_id}
                    </td>
                    <td style={{ textAlign: "left" }}>{blog?.blog_title}</td>
                    <td style={{ paddingLeft: "5.128vw" }}>
                      <div className={styles.buttonContainer}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {editBlog(blog?.blog_id)}}
                          style={{
                            backgroundColor: "#D1F4DE",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {deleteBlog(blog?.blog_id)}}
                          style={{
                            backgroundColor: "#FFD6D2",
                            height: "2.051vw",
                            width: "7.179vw",
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>
      </div>
      <Dialog
        open={isOpen.add||isOpen.update}
        onClose={handleCloseAddDialog}
        PaperProps={{
          style: {
            width: "auto",
            maxWidth: "100vw",
            height: "35.128vw",
            padding: "1.5rem",
            borderRadius: "0.513vw",
            boxShadow:
              "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
          },
        }}
      >
        <DialogTitle
          style={{
            paddingLeft: "0",
            fontWeight: "600",
            paddingTop: "0",
          }}
        >
          {isOpen.add?"Add Blog":"Update Blog"}
        </DialogTitle>
        <DialogContent style={{
          paddingTop:"1vw"
        }}>
          <div className={styles.dialogContentContainer}>
            <TextField
              type="text"
              label={"New Article Name"}
              value={blogsDetails.title}
              name="title"
              onChange={onInputChange}
            />
            <TextField
              type="text"
              name="order"
              label={"Article Order"}
              onChange={onInputChange}
              value={blogsDetails.order}
            />
            <TextField
              type="text"
              name="author"
              label={"Author"}
              onChange={onInputChange}
              value={blogsDetails.author}
            />
            <TextField
              type="text"
              name="imageURL"
              label={"Image URL"}
              onChange={onInputChange}
              value={blogsDetails.imageURL}
            />
            <div className={styles.shortDescriptionContainer}>
              <TextEditor
                propsContent={blogsDetails.shortDescription}
                propPlaceholder={"Short Description...."}
                onChangeContent={onShortDescriptionChange}
              />
            </div>
            <div className={styles.descriptionContainer}>
              <TextEditor
                propsContent={blogsDetails.description}
                propPlaceholder={"Detailed Description..."}
                onChangeContent={onDescriptionChange}
              />
            </div>
            <div className={styles.summaryContainer}>
              <TextEditor
                propsContent={blogsDetails.summary}
                propPlaceholder={"Summary..."}
                onChangeContent={onSummaryChange}
              />
            </div>
            {subTitle.map((sub, index) => (
              <div key={index} className={styles.subtitleContainer}>
                <TextField
                  type="text"
                  name="title"
                  label="Subtitle Title"
                  value={sub.title}
                  onChange={(e) => onSubtitleChange(e, index)}
                />
                <div className={styles.subDescriptionContainer}>
                  <TextEditor
                  key={index}
                    index={index}
                    propsContent={sub.description}
                    propPlaceholder={"Subtitle Description..."}
                    onChangeContent={onSubDescriptionChange}
                  />
                </div>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {onDeleteSubtitle(index)}}
                  style={{
                    backgroundColor: "#FFD6D2",
                    height: "2.051vw",
                    width: "7.179vw",
                    color: "black",
                    marginTop:"1vw",
                    textTransform: "capitalize",
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}
            <Button
              style={{
                width: "15.385vw",
                height: "2.564vw",
                padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                gap: "0.641vw",
                borderRadius: "4px",
                color: "#ffffff",
                fontSize: "0.897vw",
                backgroundColor: "#37446F",
                gridColumn: "1/2",
                boxShadow:
                  " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
              }}
              onClick={() => {
                addSubtitle();
              }}
            >
              Add Subtitle
            </Button>
            
          </div>
          <div className={styles.lowerContainer} >
          <Button
              // variant="outlined"
              style={{
                width: "15.385vw",
                height: "2.564vw",
                padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                gap: "0.641vw",
                borderRadius: "4px",
                color: "#000000",
                border: "2.03px solid #313C63",
                // borderImageSource: "linear-gradient(116.29deg, #313C63 3.44%, rgba(40, 49, 81, 0.95) 96.13%)",
                
                
                fontSize: "0.897vw",
                backgroundColor: "#ffffff",
                boxShadow:
                  " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
              }}
              onClick={() => {
                handleCloseAddDialog()
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "15.385vw",
                height: "2.564vw",
                padding: "1.026vw 2.308vw 1.026vw 2.308vw",
                gap: "0.641vw",
                borderRadius: "4px",
                color: "#ffffff",
                fontSize: "0.897vw",
                backgroundColor: "#37446F",
                boxShadow:
                  " 3.13px 3.13px 6.25px 0px #59596F80, -2.08px -2.08px 6.25px 0px #FFFFFF ",
              }}
              onClick={() => {
                isOpen.add?addBlog():updateBlog()
              }}
            >
              {isOpen.add?"Add":"Update"}
            </Button>
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
};

export default Blogs;
