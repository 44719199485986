import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./BulkLogin.module.css"
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { readFile } from "../utils/utils";
import { useLocalStorage } from "@uidotdev/usehooks";

const BulkLogin = () => {
  const [token,setToken] = useLocalStorage('token',null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [updateFile, setUpdateFile] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const [tableData,setTableData]=useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState(null);

  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(14);

  const handleAdd = async () => {
    try {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);

      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Users added successfully");
    } catch (error) {
      console.error("Error adding Users:", error);
      if (error.response && error.response.status === 409) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`Error adding Users: ${error.message}`);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpdateFileChange = (event) => {
    const file = event.target.files[0];
    setUpdateFile(file);
  };

  const fromDateChangeHandler = (value) => {
    setFromDate(value.toDate());
  };

  const toDateChangeHandler = (value) => {
    setToDate(value.toDate());
  };

  const getInstituteData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data.data;
    const temp = response.data.data.map((ele) => {
      return {
        label: `${ele.institute_name} (${ele.institute_referral_id})`,
        institute_id: ele.institute_id,
      };
    });
    setInstitutes(temp);
  };

  useEffect(() => {
    getInstituteData();
  }, []);

  const onDownloadPress = async () => {
    // console.log(selectedInstitute);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/getAll`,
        {
          responseType: "blob",
          params: {
            selectedInstitute: encodeURIComponent(
              JSON.stringify(selectedInstitute)
            ),
            fromDate: encodeURIComponent(JSON.stringify(fromDate)),
            toDate: encodeURIComponent(JSON.stringify(toDate)),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BulkUsers.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      const { data } = error.response;
      const file = await readFile(data);
      const { message } = JSON.parse(file);
      console.error(error);
      
      toast.error(message);
    }
  };

  const onDisplayPress = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/display`,
        {
          params: {
            selectedInstitute: encodeURIComponent(
              JSON.stringify(selectedInstitute)
            ),
            fromDate: encodeURIComponent(JSON.stringify(fromDate)),
            toDate: encodeURIComponent(JSON.stringify(toDate)),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTableData(response.data.data)
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message)
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("excelFile", updateFile);

      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Users added successfully");
    } catch (error) {
      console.error("Error adding Users:", error);
      if (error.response && error.response.status === 409) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`Error adding Users: ${error.message}`);
      }
    }
  };

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    );
  }

  return (
    <>
      <div>
        <h2 className="ml-3">Bulk Login Management</h2>
        <Card elevation={3} className="col-md-12 p-3 d-flex mt-3 mb-3">
          <CardContent className="d-flex flex-column w-100">
            <div className="row d-flex mb-3 justify-content-end">
              <div className="col-md-1">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAddDialog(true);
                  }}
                  style={{ width: "5%", float: "right", justifySelf: "end" }}
                  className="button-two"
                >
                  Add
                </Button>
              </div>
              <div className="col-md-1">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  style={{ width: "5%", float: "right", justifySelf: "end" }}
                  className="button-two"
                >
                  Update
                </Button>
              </div>
            </div>
            <div className="row d-flex mb-3">
              <div className="col-md-6">
                <Autocomplete
                  value={selectedInstitute}
                  onChange={(event, newValue) => {
                    setSelectedInstitute(newValue);
                  }}
                  options={institutes}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id || value === null
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Institute Name" />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="From" onChange={fromDateChangeHandler} />
                  </LocalizationProvider>
                </FormControl>
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="To" onChange={toDateChangeHandler} />
                  </LocalizationProvider>
                </FormControl>
              </div>
              <div className="col-md-1 d-flex align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDownloadPress}
                  style={{ alignSelf: "center" }}
                  className="button-two"
                >
                  Download
                </Button>
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDisplayPress}
                  style={{ alignSelf: "center" }}
                  className="button-two"
                >
                  Display
                </Button>
              </div>
            </div>
            <table className={`table  ${styles.table}`}>
                <thead>
                  <tr>
                    <th className={styles.tableCell}>User ID</th>
                    <th className={styles.tableCell}>Name</th>
                    <th className={styles.tableCell}>UserName</th>
                    <th className={styles.tableCell}>Email</th>
                    <th className={styles.tableCell}>Mobile</th>
                    <th className={styles.tableCell}>DOB</th>
                    <th className={styles.tableCell}>ReferredBy</th>
                    <th className={styles.tableCell}>Subscriptions</th>
                    <th className={styles.tableCell}>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tableData.map((data,index)=>{
                      return(
                        <tr key={index}>
                          <td className={styles.tableCell} >{data.userId}</td>
                          <td className={styles.tableCell} >{data.name}</td>
                          <td className={styles.tableCell} >{data.username}</td>
                          <td className={styles.tableCell} >{data.email}</td>
                          <td className={styles.tableCell} >{data.mobile}</td>
                          <td className={styles.tableCell} >{data.dob}</td>
                          <td className={styles.tableCell} >{data.referredBy}</td>
                          <td className={styles.tableCell} >{data.subscriptions}</td>
                          <td className={styles.tableCell} >{data.createdAt}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
          </CardContent>
        </Card>
      </div>
      <Dialog
        open={addDialog}
        fullWidth
        // maxWidth="md"
        onClose={() => {
          setAddDialog(false);
        }}
        className="d-flex flex-column justify-content-center ml-3"
      >
        <DialogTitle>Add user - Upload Excel Sheet</DialogTitle>
        <DialogContent>
          <Input
            type="file"
            fullWidth
            onChange={handleFileChange}
            // style={{ width: "20vw" }}
          />
          <ToastContainer />
          <Button
            variant="contained"
            onClick={handleAdd}
            style={{ marginTop: "1vw" }}
          >
            Add Users
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setAddDialog(false);
            }}
            className="mt-3 ml-1 button-two"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={updateDialog}
        fullWidth
        // maxWidth="md"
        onClose={() => {
          setUpdateDialog(false);
        }}
        className="d-flex flex-column justify-content-center ml-3"
      >
        <DialogTitle>Update user - Upload Excel Sheet</DialogTitle>
        <DialogContent>
          <Input
            type="file"
            fullWidth
            onChange={handleUpdateFileChange}
            // style={{ width: "20vw" }}
          />
          <ToastContainer />
          <Button
            variant="contained"
            onClick={handleUpdate}
            style={{ marginTop: "1vw" }}
          >
            Update Users
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setUpdateDialog(false);
            }}
            className="mt-3 ml-1 button-two"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BulkLogin;
