import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Switch, FormControl, InputLabel, OutlinedInput, Checkbox, ListItemText, Select, MenuItem } from "@mui/material";

const AddRoleDialog = ({ isOpen, onClose, onSave, onTabToggle, selectedTabs, newRole, setNewRole, navLink }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle style={{fontWeight: '600'}}>Add Role</DialogTitle>
      <DialogContent>
        <TextField
          label="Role"
          className="mt-2"
          value={newRole.roleName}
          onChange={(e) => setNewRole({ ...newRole, roleName: e.target.value })}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
                boxShadow: 'none',
              },
            },
          }}
          InputProps={{
            style: {
              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
            },
          }}
          InputLabelProps={{
            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
            required: false
          }}
        />
        <p style={{ display: 'flex', alignItems: 'center', paddingLeft: '14px', width: '500px', height: '36px', boxShadow: '2px 2px 4px 0px #AEAEC080', background: '#EAF1F7', marginTop: '20px', marginBottom: '8px' }}>Name</p>
        {
          navLink.map((link) => (
            (link.tag) &&
            (<FormControl key={link.id} style={{ width: '100%', marginTop: '10px' }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                    borderRadius: 'none',
                    borderBottom: '1px solid black'
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                    borderBottom: '1px solid #000000',
                    marginBottom: '1px'
                  },
                },
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label" shrink={false} style={{ color: 'black' }}
              >{link.tag}</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedTabs}
                onChange={() => onTabToggle(link.id)}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const foundLink = navLink.find((link) => {
                        if (link.tabs) {
                          return link.tabs.find((tab) => tab.id === id);
                        } else {
                          return link.id === id;
                        }
                      });
                    })
                    .join('')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
              >
                {link.tabs.map((tab) => (
                  <MenuItem key={tab.id} value={tab.id}>
                    {/* <Checkbox checked={selectedTabs.includes(tab.id)} style={{textAlign: 'right'}} /> */}
                    <ListItemText primary={tab.title} style={{ float: 'left' }} />
                    <Switch
                      checked={selectedTabs.includes(tab.id)}
                      // value={selectedTabs.includes(link.id) ? 1 : 0}
                      onChange={() => onTabToggle(tab.id)}
                      max={1}
                      step={1}
                      sx={{
                        textAlign: 'right', "&.MuiSwitch-root .Mui-checked": {
                          color: "#37446F",
                        }
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>)
          ))
        }
      </DialogContent>
      <DialogActions style={{margin: 'auto', padding: '18px'}}>
        <Button onClick={onClose} style={{ width: '15vw', border: '2px solid #37446F', background: 'none', height: '2.564vw', color: 'black', textTransform: 'none', marginRight: '10px' }}>
          Cancel
        </Button>
        <Button onClick={onSave} style={{ width: '15vw', background: '#37446F', height: '2.564vw', color: 'white', textTransform: 'none' }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRoleDialog;
