import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const AddUpdateDialog = ({ open, handleClose, formFields, newStage, setNewStage, handleSubmit }) => {
  const fieldsToExclude = ['stage_id', 'skill_id', 'is_active', 'created_at', 'updated_at'];

  const filteredFields = formFields.filter(field => !fieldsToExclude.includes(field));

  const handleDialogSubmit = async () => {
     await handleSubmit();
     handleClose();
    
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{newStage.stage_id ? 'Update' : 'Add'} Stage</DialogTitle>
      <form
        className="m-2"
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-end",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {filteredFields.map((field) => (
          <TextField
            style={{
              width: "100%",
              margin: "0.5rem auto",
            }}
            type={`${field.includes("order")?"Number":""}`}
            key={field}
            label={field}
            name={field}
            value={newStage[field] || ''}
            onChange={(e) =>
              setNewStage((prev) => ({
                ...prev,
                [field]: e.target.value,
              }))
            }
          />
        ))}
        <Button variant="contained" color="primary" onClick={handleDialogSubmit}>
          {newStage.stage_id ? 'Update' : 'Add'} Stage
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          className="ml-1 button-two"
          style={{ float: "right" }}
        >
          Close
        </Button>
      </form>
    </Dialog>
  );
};

export default AddUpdateDialog;